import { MouseEventHandler, ReactNode } from 'react'
import classNames from 'classnames'

import Button from '@/components/base/Button'
import Link from '@/components/base/Link'
import LinkButton from '@/components/base/LinkButton'
import Typography, { TypographyProps } from '@/components/base/Typography'

import { WPLink } from '@/types'

export type TextBlockContentProps = Partial<{
  doubleParagraph: boolean
  heading: Partial<{
    overline: string
    subtitle: string
    title: string
    component: TypographyProps['component']
  }>
  paragraphRegular: string
  paragraphSmall: string
  buttons: Partial<{
    shopifyButton: any
    primaryButton: any
    secondaryButton: any
  }>
  caption: {
    text: string
    link: WPLink
  }
}>

export type TextBlockSettingProps = Partial<{
  density: 'small' | 'medium' | 'large'
  titleVariant: TypographyProps['variant']
  paragraphComponent: TypographyProps['component']
  titleLink: boolean
  rootClassName: string
  headingClassName: string
  headingInnerClassName: string
  headingTitleClassName: string
  paragraphsClassName: string
  paragraphRegularClassName: string
  paragraphSmallClassName: string
}>

export type TextBlockEventsProps = Partial<{
  onPrimaryButtonClick: MouseEventHandler<HTMLAnchorElement>
  onSecondaryButtonClick: MouseEventHandler<
    HTMLAnchorElement | HTMLButtonElement
  >
}>

export type TextBlockProps = TextBlockContentProps &
  TextBlockSettingProps &
  TextBlockEventsProps

const TextBlock = ({
  heading,
  titleVariant,
  titleLink = false,
  rootClassName,
  doubleParagraph,
  headingClassName,
  headingInnerClassName,
  headingTitleClassName,
  paragraphsClassName,
  paragraphRegular,
  paragraphRegularClassName,
  paragraphSmall,
  paragraphSmallClassName,
  paragraphComponent,
  buttons,
  caption,
  density,
  onPrimaryButtonClick,
  onSecondaryButtonClick
}: TextBlockProps) => {
  const renderTitle = (title: ReactNode) => {
    const Component = heading?.component || 'span'
    return (
      <Component className={classNames('flex', headingTitleClassName)}>
        {titleLink ? (
          <Link href={buttons?.secondaryButton.url} className="flex">
            {title}
          </Link>
        ) : (
          title
        )}
      </Component>
    )
  }

  return (
    <div className={classNames('relative', rootClassName)}>
      {heading?.overline || heading?.title || heading?.subtitle ? (
        <div className={classNames('grid', headingClassName)}>
          <div
            className={classNames('grid gap-spacing-sm', headingInnerClassName)}
          >
            {heading?.overline ? (
              <Typography
                paragraph
                variant="overline"
                className="text-text-overline-light dark:text-text-overline-dark"
              >
                {heading?.overline}
              </Typography>
            ) : null}
            {heading?.title
              ? renderTitle(
                  <Typography
                    variant={titleVariant}
                    className="text-text-primary-light dark:text-text-primary-dark"
                    data-motion-title
                  >
                    {heading?.title}
                  </Typography>
                )
              : null}
            {heading?.subtitle ? (
              <Typography
                paragraph
                variant="large"
                className="text-text-secondary-light dark:text-text-secondary-dark"
              >
                {heading?.subtitle}
              </Typography>
            ) : null}
          </div>
        </div>
      ) : null}

      {paragraphRegular ||
      paragraphSmall ||
      buttons?.primaryButton ||
      buttons?.secondaryButton ? (
        <div
          className={classNames(
            'grid grid-flow-row',
            {
              'gap-spacing-md': paragraphRegular || paragraphSmall
            },
            {
              'mt-spacing-sm': density === 'small' && heading?.title,
              'mt-spacing-lg': density === 'medium' && heading?.title,
              'mt-spacing-2xl': density === 'large' && heading?.title
            }
          )}
        >
          {paragraphRegular || paragraphSmall ? (
            <div
              className={classNames(
                'grid gap-x-gutter gap-y-spacing-sm',
                paragraphsClassName
              )}
            >
              {paragraphRegular ? (
                <Typography
                  // paragraph
                  variant="regular"
                  component={paragraphComponent}
                  className={classNames(
                    'block text-text-secondary-light dark:text-text-secondary-dark',
                    paragraphRegularClassName
                  )}
                  data-motion-paragraph-regular
                >
                  {paragraphRegular}
                </Typography>
              ) : null}
              {paragraphSmall && doubleParagraph ? (
                <Typography
                  // paragraph
                  variant="small"
                  className={classNames(
                    'block text-text-secondary-light dark:text-text-secondary-dark',
                    paragraphSmallClassName
                  )}
                  data-motion-paragraph-small
                >
                  {paragraphSmall}
                </Typography>
              ) : null}
            </div>
          ) : null}

          {buttons?.primaryButton ||
          buttons?.secondaryButton ||
          buttons?.shopifyButton ? (
            <div className="flex space-x-spacing-xxs">
              {buttons?.shopifyButton && buttons?.shopifyButton.id ? (
                <div id={buttons?.shopifyButton.id} />
              ) : null}
              {buttons?.primaryButton ? (
                <LinkButton
                  variant="contained"
                  href={buttons?.primaryButton.url}
                  onClick={onPrimaryButtonClick}
                >
                  {buttons?.primaryButton.title}
                </LinkButton>
              ) : null}
              {buttons?.secondaryButton ? (
                <>
                  {buttons?.secondaryButton.asButton ? (
                    <Button variant="outlined" onClick={onSecondaryButtonClick}>
                      {buttons?.secondaryButton.title}
                    </Button>
                  ) : (
                    <LinkButton
                      variant="outlined"
                      href={buttons?.secondaryButton.url}
                      onClick={onSecondaryButtonClick}
                    >
                      {buttons?.secondaryButton.title}
                    </LinkButton>
                  )}
                </>
              ) : null}
            </div>
          ) : null}

          {caption?.text || caption?.link ? (
            <Typography
              paragraph
              variant="small"
              className="text-text-secondary-light dark:text-text-secondary-dark"
            >
              {`${caption?.text}${caption?.link ? `<a href="${caption?.link.url}" target="${caption?.link.target}">${caption?.link.title}</a>` : ''}`}
            </Typography>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default TextBlock
