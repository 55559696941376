import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import ImageRatio from '@/components/base/ImageRatio'
import Link from '@/components/base/Link'
import LinkButton from '@/components/base/LinkButton'
import Section from '@/components/base/Section'
import Typography from '@/components/base/Typography'

import type { SectionTheme, WPImage } from '@/types'

type RelatedCardsProps = SectionTheme & {
  items?: {
    link: string
    featuredImage: Partial<WPImage>
    title: {
      rendered: string
    }
    text?: string
    buttons: Partial<{
      primaryButton: any
      secondaryButton: any
    }>
  }[]
}

const RelatedCards = ({ theme, items = [] }: RelatedCardsProps) => {
  return (
    <Section theme={theme} className="overflow-hidden">
      <Container>
        <Grid>
          <div className="col-span-2 md:col-span-6 lg:col-span-12">
            <Swiper
              modules={[Pagination]}
              className="select-none !overflow-visible !pb-spacing-md [--swiper-pagination-bottom:0] [--swiper-pagination-bullet-inactive-color:#1C1917] [--swiper-pagination-bullet-inactive-opacity:1] [--swiper-pagination-color:#1C1917] dark:[--swiper-pagination-bullet-inactive-color:white] dark:[--swiper-pagination-color:white]"
              centerInsufficientSlides={true}
              spaceBetween={20}
              slidesPerView={1}
              slidesPerGroup={1}
              pagination={{
                type: 'bullets',
                enabled: true,
                dynamicBullets: true
              }}
              breakpoints={{
                768: {
                  spaceBetween: 30,
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                  pagination: { enabled: false }
                },
                1024: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                  pagination: { enabled: false }
                }
              }}
            >
              {items.map(
                ({ featuredImage, title, text, link, buttons }, index) => (
                  <SwiperSlide key={`slide-${index}`} className="!h-auto">
                    <div className="relative flex h-full w-full flex-col justify-between space-y-spacing-md border border-border-thumb-grid-light px-spacing-lg py-spacing-sm dark:border-border-thumb-grid-dark">
                      <div className="grid gap-spacing-md">
                        <Link href={link}>
                          <ImageRatio
                            src={
                              featuredImage?.sizes?.large.url ||
                              featuredImage?.url ||
                              ''
                            }
                            alt={featuredImage?.alt || ''}
                            ratio="1:1"
                          />
                        </Link>
                        <Typography
                          component="h3"
                          variant="h3"
                          className="text-text-primary-light dark:text-text-primary-dark"
                        >
                          {text}
                        </Typography>
                        <Typography
                          component="h6"
                          variant="h6"
                          className="text-text-secondary-light dark:text-text-secondary-dark"
                        >
                          {title.rendered}
                        </Typography>
                      </div>
                      <div>
                        {buttons && buttons?.primaryButton ? (
                          <LinkButton
                            variant="contained"
                            href={buttons?.primaryButton.url}
                          >
                            {buttons?.primaryButton.title}
                          </LinkButton>
                        ) : null}
                      </div>
                    </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </div>
        </Grid>
      </Container>
    </Section>
  )
}

export default RelatedCards
