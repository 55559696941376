import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

export type SpacerProps = HTMLAttributes<HTMLHRElement> & {
  variant?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}

const Spacer = forwardRef<HTMLHRElement, SpacerProps>(
  ({ variant = '2xl', className, ...props }, ref) => {
    return (
      <hr
        ref={ref}
        className={classNames(
          'border-0 bg-background-primary-light dark:bg-background-primary-dark',
          {
            'pt-s-spacer-xxs md:pt-d-spacer-xxs': variant === 'xxs',
            'pt-s-spacer-xs md:pt-d-spacer-xs': variant === 'xs',
            'pt-s-spacer-sm md:pt-d-spacer-sm': variant === 'sm',
            'pt-s-spacer-md md:pt-d-spacer-md': variant === 'md',
            'pt-s-spacer-lg md:pt-d-spacer-lg': variant === 'lg',
            'pt-s-spacer-xl md:pt-d-spacer-xl': variant === 'xl',
            'pt-s-spacer-2xl md:pt-d-spacer-2xl': variant === '2xl'
          },
          className
        )}
        {...props}
      />
    )
  }
)

export default Spacer
