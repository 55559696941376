import {
  AnchorHTMLAttributes,
  PropsWithChildren,
  useEffect,
  useState
} from 'react'
import classNames from 'classnames'

import type { ButtonProps } from '@/components/base/Button'
import Link, { LinkProps } from '@/components/base/Link'
import Typography from '@/components/base/Typography'
import { isExternalLink } from '@/utils/path'

type AnchorLinkProps = AnchorHTMLAttributes<HTMLAnchorElement>
type NextLinkProps = PropsWithChildren<LinkProps>

type LinkButtonProps = (NextLinkProps | AnchorLinkProps) &
  Pick<ButtonProps, 'variant' | 'selected' | 'className'>

const LinkButtonText = ({ children }: PropsWithChildren) => {
  return (
    <span
      data-label={children as string}
      className="relative block overflow-hidden p-[.4rem] after:absolute after:left-0 after:top-0 after:-mb-[0.35em] after:-mt-[0.3em] after:w-full after:translate-y-full after:p-[.4rem] after:font-roboto after:text-small after:transition-transform after:duration-300 after:ease-out-cubic after:content-[attr(data-label)] pointer:group-hover:after:translate-y-0"
    >
      <Typography
        variant="small"
        className="block transition-transform duration-300 ease-out-cubic pointer:group-hover:-translate-y-full"
      >
        {children}
      </Typography>
    </span>
  )
}

const LinkButton = ({
  variant = 'contained',
  children,
  className,
  selected = false,
  ...props
}: LinkButtonProps) => {
  const [needsExternalLink, setNeedsExternalLink] = useState(false)

  const variantClasses = classNames(
    'inline-flex p-[1.2rem]',
    {
      'border border-surface-primay-light bg-surface-primay-light text-text-button-primary-light dark:border-surface-primay-dark dark:bg-surface-primay-dark dark:text-text-button-primary-dark':
        variant === 'contained'
    },
    {
      'border border-border-primary-light text-text-button-secondary-light dark:border-border-primary-dark dark:text-text-button-secondary-dark':
        variant === 'outlined'
    },
    {
      'rounded-radius-minimal border border-transparent text-text-button-secondary-light dark:text-text-button-secondary-dark transition-colors duration-500 ease-in-out-cubic':
        variant === 'rounded',
      'bg-surface-button-filter-selected-light dark:bg-surface-button-filter-selected-dark cursor-default pointer-events-none':
        variant === 'rounded' && selected
    }
  )

  useEffect(() => {
    setNeedsExternalLink(isExternalLink(props.href as string) || false)
  }, [props.href])

  return needsExternalLink ? (
    <a
      {...(props as AnchorLinkProps)}
      className={classNames('group', variantClasses, className)}
      target="_blank"
    >
      <LinkButtonText>{children}</LinkButtonText>
    </a>
  ) : (
    <Link
      {...(props as NextLinkProps)}
      className={classNames('group', variantClasses, className)}
    >
      <LinkButtonText>{children}</LinkButtonText>
    </Link>
  )
}

export default LinkButton
