import { Fragment, useCallback, useMemo, useRef, useState } from 'react'
import { usePrevious } from 'react-use'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import Flip from 'gsap/dist/Flip'
import { find, forEach, includes } from 'lodash'

import Button from '@/components/base/Button'
import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import Link from '@/components/base/Link'
import ProductCard, { Product } from '@/components/base/ProductCard'
import Section from '@/components/base/Section'
import Select from '@/components/base/Select'
import Typography from '@/components/base/Typography'
import Spacer from '@/components/sections/Spacer'

import type { SectionTheme } from '@/types'

type Filter = {
  label: string
  value: number
}

type Category = {
  id: number
  count: number
  description: string
  name: string
  slug: string
  acf: {
    isProductLine: boolean
  }
}

type ProductItem = Product & {
  tipologia: number[]
  categories: number[]
  line: number[]
}

type ProductListProps = SectionTheme & {
  categories: Category[]
  grid: ProductItem[]
  lines: ProductItem[][]
  filters: Filter[]
}

const ProductList = ({
  theme,
  lines,
  filters,
  categories
}: ProductListProps) => {
  useMemo(() => gsap.registerPlugin(Flip), [])
  const [currentFilter, setCurrentFilter] = useState<Filter>(filters[0])
  const previousFilter = usePrevious(currentFilter)
  const container = useRef<HTMLDivElement>(null)
  const root = useRef<HTMLElement>(null)

  const findCurrentFilter = useCallback(
    (value: number) =>
      filters.find((filter) => filter.value === value) as Filter,
    [filters]
  )

  const getLineById = useCallback(
    (id: number) =>
      categories.find((category) => category.id === id) as Category,
    [categories]
  )

  useGSAP(
    ({ selector: gsapSelector }: gsap.Context) => {
      if (!previousFilter?.value) return

      const selector = gsapSelector || gsap.utils.selector(root.current)

      const currentCategory = find(
        categories,
        (category) => category.id === currentFilter.value
      ) as Category

      const isIndex = currentCategory.id === filters[0].value
      const isLine = currentCategory.acf.isProductLine || isIndex

      const products = selector('[data-category]') as HTMLElement[]

      const state = Flip.getState(products)
      const initialHeight = root.current?.clientHeight || 0

      forEach(products, (product) => {
        const dataAttribute = product.getAttribute(
          isLine ? 'data-line' : 'data-category'
        ) as string
        const active = isIndex
          ? parseFloat(dataAttribute) !== -1
          : includes(dataAttribute.split(','), currentFilter.value.toString())
        gsap.set(product, { display: active ? 'block' : 'none' })
      })

      const finalHeight = root.current?.clientHeight || 0

      Flip.from(state, {
        duration: 1,
        scale: true,
        ease: 'power3.inOut',
        // stagger: 0.1,
        absolute: true,
        simple: true,
        onStart: () => {
          gsap.set(container.current, {
            height: finalHeight < initialHeight ? initialHeight : finalHeight
          })
        },
        onComplete: () => {
          gsap.set(container.current, { clearProps: 'height' })
        },
        onEnter: (elements) =>
          gsap.fromTo(
            elements,
            { opacity: 0, scale: 0.95 },
            {
              delay: 0.6,
              opacity: 1,
              scale: 1,
              duration: 0.6,
              ease: 'power3.out'
            }
          ),
        onLeave: (elements) =>
          gsap.to(elements, {
            opacity: 0,
            scale: 0.95,
            duration: 0.6,
            ease: 'power3.inOut'
          })
      })
    },
    {
      dependencies: [currentFilter],
      scope: root
    }
  )

  return (
    <>
      <Section theme={theme}>
        <Container>
          <Grid>
            <div className="col-span-2 md:col-span-6 lg:col-span-12">
              <Select
                className="lg:hidden"
                value={currentFilter?.value}
                options={filters}
                onChange={(event) =>
                  setCurrentFilter(
                    findCurrentFilter(parseInt(event.target.value))
                  )
                }
              />
              <div className="flex space-x-spacing-xxs">
                {filters.map(({ label, value }, index) => (
                  <Button
                    key={`filter-${index}`}
                    variant="rounded"
                    selected={value === currentFilter.value}
                    className="hidden lg:flex"
                    onClick={() => setCurrentFilter(findCurrentFilter(value))}
                  >
                    {label}
                  </Button>
                ))}
              </div>
            </div>
          </Grid>
        </Container>
      </Section>

      <Spacer theme={theme} variant="xs" />

      <Section ref={root} theme={theme}>
        <Container ref={container}>
          <Grid className="gap-y-spacing-sm md:gap-y-gutter">
            {categories.map((category, index) => (
              <Fragment key={`category-${index}`}>
                {!category.acf.isProductLine && category.description ? (
                  <div
                    className="col-span-2 will-change-transform md:col-span-3 lg:col-span-4"
                    data-category={category.id}
                    data-line={-1}
                    style={{ display: 'none' }}
                  >
                    <div className="flex h-full w-full items-center py-spacing-lg md:py-0">
                      <div className="aspect-h-5 aspect-w-4 relative w-full">
                        <div className="absolute left-0 top-0 flex h-full w-full items-center px-spacing-lg">
                          <div className="grid gap-y-spacing-md">
                            <Typography
                              variant="h4"
                              component="h4"
                              className="text-text-primary-light dark:text-text-primary-dark"
                            >
                              {category.name}
                            </Typography>
                            <Typography
                              paragraph
                              variant="regular"
                              className="text-text-secondary-light dark:text-text-secondary-dark"
                            >
                              {category.description}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </Fragment>
            ))}
            {lines.map((products, index) => {
              const line = getLineById(products[0].line[0])

              return (
                <Fragment key={`line-${index}`}>
                  {line.acf.isProductLine ? (
                    <div
                      className="col-span-2 will-change-transform md:col-span-3 lg:col-span-4"
                      data-line={line.id}
                      data-category={line.id}
                    >
                      <div className="flex h-full w-full items-center py-spacing-lg md:py-0">
                        <div className="aspect-h-5 aspect-w-4 relative w-full">
                          <div className="absolute left-0 top-0 flex h-full w-full items-center px-spacing-lg">
                            <div className="grid gap-y-spacing-md">
                              <Typography
                                variant="h4"
                                component="h4"
                                className="text-text-primary-light dark:text-text-primary-dark"
                              >
                                {line.name}
                              </Typography>
                              <Typography
                                paragraph
                                variant="regular"
                                className="text-text-secondary-light dark:text-text-secondary-dark"
                              >
                                {line.description}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {products.map(({ link, ...product }, index) => (
                    <div
                      key={`product-${index}`}
                      className="col-span-2 will-change-transform md:col-span-3 lg:col-span-4"
                      data-category={product.categories.join(',')}
                      data-line={line.acf.isProductLine ? line.id : -1}
                      style={{
                        display: line.acf.isProductLine ? 'block' : 'none'
                      }}
                    >
                      {link ? (
                        <Link href={link}>
                          <ProductCard {...product} />
                        </Link>
                      ) : (
                        <ProductCard {...product} />
                      )}
                    </div>
                  ))}
                </Fragment>
              )
            })}
          </Grid>
        </Container>
      </Section>
    </>
  )
}

export default ProductList
