import { Fragment, useState } from 'react'
import classNames from 'classnames'
import { useFormik } from 'formik'

import Container from '@/components/base/Container'
import FormControl from '@/components/base/FormControl'
import Grid from '@/components/base/Grid'
import Section from '@/components/base/Section'
import SubmitButton from '@/components/base/SubmitButton'
import Typography from '@/components/base/Typography'
import { useLocaleStore } from '@/store/locale'
import {
  convertJsontoFormData,
  createInitialValues,
  createYupSchema
} from '@/utils/form'
import { API } from '@/constants'
import { axiosServer as axios } from '@/base/axios'

import { useStore } from '@/contexts/store'
import { SectionTheme, WPCF7Form } from '@/types'

type NewsletterProps = SectionTheme &
  Partial<{
    id: string
    form: WPCF7Form['form']
  }>

const Newsletter = ({ theme, id, form: fields = [] }: NewsletterProps) => {
  const currentLocale = useLocaleStore((state) => state.currentLocale)
  const [statusMessage, setStatusMessage] = useState('')
  const { strings } = useStore()

  async function sendContactForm(values: any) {
    const response = await axios.post(
      `${API.CF7_FEEDBACK}/${id}/feedback/`,
      convertJsontoFormData(values),
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    return response
  }

  const formik = useFormik<any>({
    initialValues: { ...createInitialValues(fields) } || {},
    validationSchema: createYupSchema(fields, currentLocale),
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      const result = sendContactForm(values)
      result
        .then((response) => {
          setStatusMessage(response.data.message)
          setStatus(response.data.status === 'mail_sent' ? 'success' : 'error')
        })
        .then(() => {
          setTimeout(() => {
            resetForm()
            setStatusMessage('')
            setStatus('')

            setSubmitting(false)
          }, 5000)
        })
    }
  })

  return (
    <Section theme={theme}>
      <Container>
        <Grid>
          <div className="col-span-2 md:col-span-6 lg:col-span-12">
            <form
              noValidate
              autoComplete="off"
              onSubmit={formik.handleSubmit}
              className="grid h-auto w-full grid-cols-2 gap-x-gutter gap-y-spacing-xxs md:grid-cols-6 lg:grid-cols-12"
            >
              {fields.map((field) => (
                <Fragment key={field.name}>
                  {field.type === 'email' ? (
                    <div className="col-span-2 flex md:col-span-6 lg:col-span-12">
                      <FormControl
                        id={`${field.name}_${id}`}
                        name={field.name}
                        type={field.type}
                        label={field.label}
                        required={field.required}
                        placeholder={`${field.placeholder}${field.required ? ' *' : ''}`}
                        checked={formik.values[field.name] || false}
                        value={formik.values[field.name]}
                        disabled={formik.isSubmitting}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="grow"
                        error={
                          formik.errors[field.name] !== undefined &&
                          formik.touched[field.name] !== undefined
                        }
                      />
                      <SubmitButton
                        variant="contained"
                        className="shrink-0"
                        fetching={formik.isSubmitting}
                        disabled={
                          formik.isSubmitting ||
                          (Object.values(formik.errors).length > 0 &&
                            formik.submitCount > 0)
                        }
                      >
                        {strings?.newsletterFormSubmit}
                      </SubmitButton>
                    </div>
                  ) : (
                    <FormControl
                      id={`${field.name}_${id}`}
                      name={field.name}
                      type={field.type}
                      label={field.label}
                      required={field.required}
                      placeholder={`${field.placeholder}${field.required ? ' *' : ''}`}
                      checked={formik.values[field.name] || false}
                      value={formik.values[field.name]}
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="col-span-2 mt-spacing-sm md:col-span-6 lg:col-span-12"
                      error={
                        formik.errors[field.name] !== undefined &&
                        formik.touched[field.name] !== undefined
                      }
                    />
                  )}
                </Fragment>
              ))}

              <div className="col-span-2 mt-spacing-sm md:col-span-6 lg:col-span-12">
                <Typography
                  paragraph
                  variant="regular"
                  className={classNames('text-text-overline-light', {
                    invisible: statusMessage === '',
                    visible: statusMessage !== ''
                  })}
                >
                  {statusMessage}
                </Typography>
              </div>
            </form>
          </div>
        </Grid>
      </Container>
    </Section>
  )
}

export default Newsletter
