import { SelectHTMLAttributes, useMemo } from 'react'
import classNames from 'classnames'

import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'

type Option = {
  label: string
  value: number | string
}

export type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  options: Option[]
}

const Select = ({ options, className, onChange, ...props }: SelectProps) => {
  const selection = useMemo<Option>(
    () => options.find((option) => option.value === props.value)!,
    [props.value, options]
  )

  return (
    <div className={classNames('relative', className)}>
      <button className="boder flex w-full items-center justify-between border border-border-primary-light p-spacing-sm">
        <Typography variant="regular">{selection?.label}</Typography>
        <SVGIcon symbol="chevron-icon" className="h-[.7rem] w-[1.2rem]" />
      </button>
      <select
        className="absolute left-0 top-0 h-full w-full px-spacing-sm opacity-0"
        onChange={(event) => {
          onChange && onChange(event)
        }}
        {...props}
      >
        {options?.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select
