import { forwardRef } from 'react'

import ImageText, { ImageTextProps } from '@/components/sections/ImageText'

export type NewsCardProps = Omit<
  ImageTextProps,
  | 'density'
  | 'titleVariant'
  | 'doubleParagraph'
  | 'backgroundImage'
  | 'smallImage'
>

const NewsCard = forwardRef<HTMLElement, NewsCardProps>((props, ref) => {
  return (
    <ImageText
      ref={ref}
      doubleParagraph={false}
      backgroundImage={false}
      smallImage={true}
      textShrink={true}
      imageLink={true}
      titleLink={true}
      titleVariant="h4"
      density="small"
      {...props}
    />
  )
})

export default NewsCard
