/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useRef } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'
import { useGSAP } from '@gsap/react'
import classNames from 'classnames'
import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'
import Image from 'next/image'
import { shallow } from 'zustand/shallow'

import Banner, { BannerProps } from '@/components/base/Banner'
import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import Section from '@/components/base/Section'
import TextBlock, { TextBlockContentProps } from '@/components/base/TextBlock'
import { useAgeGateStore } from '@/store/agegate'
import { useLayoutStore } from '@/store/layout'
import { useLoaderStore } from '@/store/loader'
import { useRouterStore } from '@/store/router'
import { MediaQuery } from '@/constants'

import { MASK_TRANSITION_LEAVE_DURATION } from '@/contexts/transition'
import type { WPImage, WPVideo } from '@/types'

type HeroProps = Pick<TextBlockContentProps, 'heading' | 'paragraphRegular'> &
  Partial<{
    alignment: 'left' | 'center' | 'right'
    posterDesktop: Partial<WPImage>
    posterMobile: Partial<WPImage>
    videoDesktop: Partial<WPVideo>
    videoMobile: Partial<WPVideo>
    useTextonly: boolean
    banner: Partial<{
      hasBanner: boolean
      items: BannerProps['items']
    }>
  }>

const HeroImage = ({
  posterMobile,
  posterDesktop
}: Pick<HeroProps, 'posterMobile' | 'posterDesktop'>) => {
  return (
    <>
      {posterMobile ? (
        <Image
          className="block object-cover object-center lg:hidden"
          src={posterMobile.sizes?.large.url || posterMobile?.url || ''}
          alt={posterMobile.alt || ''}
          data-motion-hero-image-mobile
          priority={true}
          fill={true}
        />
      ) : null}

      {posterDesktop ? (
        <Image
          className="hidden object-cover object-center lg:block"
          src={posterDesktop.sizes?.large.url || posterDesktop?.url || ''}
          alt={posterDesktop.alt || ''}
          data-motion-hero-image-desktop
          priority={true}
          fill={true}
        />
      ) : null}
    </>
  )
}

const HeroVideo = ({
  videoMobile,
  videoDesktop
}: Pick<HeroProps, 'videoMobile' | 'videoDesktop'>) => {
  return (
    <>
      {videoMobile ? (
        <video
          preload="auto"
          loop
          muted
          autoPlay
          playsInline
          className="absolute left-0 top-0 block h-full w-full object-cover object-center lg:hidden"
          data-motion-hero-video-mobile
        >
          <source src={videoMobile.url} type="video/mp4" />
        </video>
      ) : null}

      {videoDesktop ? (
        <video
          preload="auto"
          loop
          muted
          autoPlay
          playsInline
          className="absolute left-0 top-0 hidden h-full w-full object-cover object-center lg:block"
          data-motion-hero-video-desktop
        >
          <source src={videoDesktop.url} type="video/mp4" />
        </video>
      ) : null}
    </>
  )
}

const Hero = ({
  alignment = 'left',
  posterMobile,
  posterDesktop,
  videoMobile,
  videoDesktop,
  useTextonly,
  banner,
  ...props
}: HeroProps) => {
  const root = useRef<HTMLDivElement>(null)
  const hasMedia =
    posterMobile?.url || posterDesktop?.url || videoMobile || videoDesktop

  const { context, contextSafe } = useGSAP({ scope: root })

  const revealTimeline = contextSafe(() => {
    const selector = context.selector || gsap.utils.selector(root)

    const title = selector('[data-motion-title]')[0]
    const paragraph = selector('[data-motion-paragraph-regular]')[0]

    const splitTitle = new SplitText(title, {
      type: 'words,chars',
      wordsClass: 'word',
      charsClass: 'char'
    })

    const splitParagraph = new SplitText(paragraph, {
      type: 'lines',
      linesClass: 'line'
    })
    new SplitText(paragraph, { type: 'lines', linesClass: 'line-wrap' })

    const textTimeline = gsap.timeline({
      onComplete: () => {
        // splitTitle.revert()
        // splitParagraph.revert()
      }
    })

    splitTitle.chars.length > 0 &&
      textTimeline.add(
        gsap.fromTo(
          splitTitle.chars,
          { opacity: 0, filter: 'blur(3px)' },
          {
            opacity: 1,
            filter: 'blur(0px)',
            duration: 1.8,
            ease: 'power2.inOut',
            stagger: {
              from: 'random',
              amount: 0.4
            }
          }
        )
      )

    splitParagraph.lines.length > 0 &&
      textTimeline.add(
        gsap.fromTo(
          splitParagraph.lines,
          { yPercent: 100 },
          { yPercent: 0, duration: 1.2, stagger: 0.1, ease: 'power2.inOut' }
        ),
        '<+0.4'
      )

    const mm = gsap.matchMedia(root)

    mm.add(MediaQuery.SMALL, () => {
      const image = selector('[data-motion-hero-image-mobile]')[0]
      const video = selector('[data-motion-hero-video-mobile]')[0]
      const poster = video || image

      const timeline = gsap.timeline()

      poster &&
        timeline.add(
          gsap.fromTo(
            poster,
            { scale: 1.2, opacity: 0 },
            { scale: 1, opacity: 1, duration: 3, ease: 'power2.out' }
          )
        )

      timeline.add(textTimeline, `<+${poster ? 0.2 : 0}`)
    })

    mm.add(MediaQuery.LARGE, () => {
      const image = selector('[data-motion-hero-image-desktop]')[0]
      const video = selector('[data-motion-hero-video-desktop]')[0]
      const poster = video || image

      const timeline = gsap.timeline()

      poster &&
        timeline.add(
          gsap.fromTo(
            poster,
            { scale: 1.2, opacity: 0 },
            { scale: 1, opacity: 1, duration: 3, ease: 'power2.out' }
          )
        )

      timeline.add(textTimeline, `<+${poster ? 0.2 : 0}`)
    })
  })

  useEffect(() => {
    const updateState = ({
      pageLoaded,
      needsAgeGate,
      needsVideoIntro
    }: any) => {
      if (pageLoaded && !needsAgeGate && !needsVideoIntro) {
        revealTimeline()
      }
    }

    const unsubscribeAgeGate = useAgeGateStore.subscribe(
      (state) => state.needsAgeGate,
      (needsAgeGate) => {
        updateState({
          pageLoaded: useLoaderStore.getState().pageLoaded,
          needsAgeGate,
          needsVideoIntro:
            useLayoutStore.getState().needsVideoIntro &&
            !useLayoutStore.getState().videoIntroSkipped
        })
      }
    )

    const unsubscribeVideoIntro = useLayoutStore.subscribe(
      (state) => [state.needsVideoIntro, state.videoIntroSkipped],
      ([needsVideoIntro, videoIntroSkipped]) => {
        updateState({
          pageLoaded: useLoaderStore.getState().pageLoaded,
          needsAgeGate: useAgeGateStore.getState().needsAgeGate,
          needsVideoIntro: needsVideoIntro && !videoIntroSkipped
        })
      },
      {
        equalityFn: shallow
      }
    )

    const unsubscribeLoader = useLoaderStore.subscribe(
      (state) => state.pageLoaded,
      (pageLoaded) => {
        updateState({
          pageLoaded,
          needsAgeGate: useAgeGateStore.getState().needsAgeGate,
          needsVideoIntro:
            useLayoutStore.getState().needsVideoIntro &&
            !useLayoutStore.getState().videoIntroSkipped
        })
      }
    )

    return () => {
      unsubscribeLoader()
      unsubscribeAgeGate()
      unsubscribeVideoIntro()
    }
  }, [])

  return (
    <Section
      ref={root}
      theme={hasMedia ? 'light' : 'dark'}
      className="relative flex h-full w-full flex-row items-center justify-center"
    >
      <Container>
        {hasMedia ? (
          <div
            className={classNames(
              'absolute left-0 top-0 h-full w-full overflow-hidden bg-black-light',
              'after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-black-light/40'
            )}
          >
            {(posterMobile || posterDesktop) &&
            !(videoMobile || videoDesktop) ? (
              <HeroImage
                posterMobile={posterMobile}
                posterDesktop={posterDesktop}
              />
            ) : null}

            {(videoMobile || videoDesktop) &&
            !(posterMobile || posterDesktop) ? (
              <HeroVideo
                videoMobile={videoMobile}
                videoDesktop={videoDesktop}
              />
            ) : null}
          </div>
        ) : null}

        <Grid className="dark relative">
          <TextBlock
            rootClassName={classNames({
              'col-span-2 md:col-span-4 lg:col-start-2': alignment === 'left',
              'col-span-2 md:col-span-6 lg:col-span-6 lg:col-start-4 text-center':
                alignment === 'center',
              'col-span-2 md:col-span-4 md:col-start-3 lg:col-start-8':
                alignment === 'right'
            })}
            headingTitleClassName={classNames({
              'justify-start': alignment === 'left' || alignment === 'right',
              'justify-center': alignment === 'center'
            })}
            paragraphsClassName={classNames({
              'grid-cols-2 md:grid-cols-6 lg:grid-cols-6':
                alignment === 'center'
            })}
            paragraphRegularClassName={classNames({
              'col-span-2 md:col-span-4 md:col-start-2 lg:col-span-4 lg:col-start-2':
                alignment === 'center'
            })}
            paragraphComponent="h2"
            titleVariant="h1"
            density="large"
            {...props}
          />
        </Grid>
      </Container>

      {banner?.hasBanner ? (
        <Container className="absolute bottom-0 left-1/2 -translate-x-1/2">
          <Grid>
            <div className="relative col-span-2 md:col-span-3 md:col-start-4 lg:col-span-4 lg:col-start-9">
              <Banner
                items={banner.items}
                className="!absolute -left-[2rem] -right-[2rem] bottom-0 h-auto md:left-0 md:right-0"
              />
            </div>
          </Grid>
        </Container>
      ) : null}
    </Section>
  )
}

export default Hero
