import { forwardRef, useState } from 'react'
import classNames from 'classnames'
import Image, { ImageProps } from 'next/image'

export type LazyImageProps = ImageProps

const LazyImage = forwardRef<HTMLImageElement, LazyImageProps>(
  ({ src, alt, className, ...props }, ref) => {
    const [loaded, setLoaded] = useState(false)

    return (
      <Image
        ref={ref}
        src={src}
        alt={alt}
        loading="lazy"
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        onLoad={() => setLoaded(true)}
        className={classNames(
          'transition-opacity duration-300 ease-in-out-cubic',
          {
            'opacity-0': !loaded,
            'opacity-100': loaded
          },
          className
        )}
        {...props}
      />
    )
  }
)

export default LazyImage
