import { Fragment, PropsWithChildren, useState } from 'react'
import classNames from 'classnames'

import Container from '@/components/base/Container'
import Section from '@/components/base/Section'
import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'

import type { SectionTheme } from '@/types'

type ProcessContentProps = {
  title: string
  text: string
}

type ProcessAccordionProps = ProcessContentProps &
  Partial<{
    active: boolean
    className: string
    onToggle: () => void
  }>

type ProcessCardProps = ProcessContentProps & {
  className?: string
}

type ProcessProps = SectionTheme & {
  items?: ProcessContentProps[]
}

const ProcessTitle = ({ children }: PropsWithChildren) => {
  return (
    <Typography
      variant="h6"
      component="h6"
      className="text-text-primary-light dark:text-text-primary-dark"
    >
      {children}
    </Typography>
  )
}

const ProcessText = ({
  children,
  className
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <Typography
      paragraph
      variant="small"
      className={classNames(
        'text-text-secondary-light dark:text-text-secondary-dark',
        className
      )}
    >
      {children}
    </Typography>
  )
}

const ProcessAccordion = ({
  active,
  title,
  text,
  className,
  onToggle
}: ProcessAccordionProps) => {
  return (
    <div
      className={classNames(
        'flex w-full basis-full flex-col border-b border-r border-border-thumb-grid-light dark:border-border-thumb-grid-dark',
        className
      )}
    >
      <button
        onClick={onToggle}
        className="flex items-center justify-between space-x-spacing-sm p-spacing-lg text-left"
      >
        <ProcessTitle>{title}</ProcessTitle>
        <SVGIcon
          symbol="more-icon"
          className={classNames(
            'h-[1.2rem] w-[1.2rem] fill-text-primary-light transition-all duration-300 ease-in-out-cubic dark:fill-text-primary-dark',
            {
              'rotate-0': !active,
              '-rotate-[135deg]': active
            }
          )}
        />
      </button>
      <div
        className={classNames(
          'pointer-events-none grid -translate-y-spacing-xs overflow-hidden transition-all duration-300 ease-in-out-cubic',
          { 'grid-rows-[0fr] opacity-0': !active },
          { 'grid-rows-[1fr] opacity-100': active }
        )}
      >
        <div className="overflow-hidden">
          <ProcessText className="px-spacing-lg pb-spacing-sm pt-0">
            {text}
          </ProcessText>
        </div>
      </div>
    </div>
  )
}

const ProcessCard = ({ title, text, className }: ProcessCardProps) => {
  return (
    <div
      className={classNames(
        'flex grow basis-full flex-col space-y-spacing-sm border-b border-r border-border-thumb-grid-light p-spacing-lg md:flex-row md:space-x-gutter md:space-y-0 lg:basis-1/2 lg:items-center dark:border-border-thumb-grid-dark',
        className
      )}
    >
      <div className="flex basis-1/2 items-center">
        <ProcessTitle>{title}</ProcessTitle>
      </div>
      <div className="flex basis-1/2 items-center">
        <ProcessText>{text}</ProcessText>
      </div>
    </div>
  )
}

const Process = ({ theme, items }: ProcessProps) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <Section theme={theme}>
      <Container>
        <div className="flex flex-wrap border-l border-t border-border-thumb-grid-light dark:border-border-thumb-grid-dark">
          {items?.map((card, index) => (
            <Fragment key={`card-${index}`}>
              <ProcessCard className="hidden md:flex" {...card} />
              <ProcessAccordion
                className="flex md:hidden"
                active={index === activeIndex}
                onToggle={() =>
                  setActiveIndex(index !== activeIndex ? index : -1)
                }
                {...card}
              />
            </Fragment>
          ))}
        </div>
      </Container>
    </Section>
  )
}

export default Process
