/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from 'react'
import Image from 'next/image'

import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import LinkButton from '@/components/base/LinkButton'
import Modal, { ModalProps } from '@/components/base/Modal'
import Section from '@/components/base/Section'
import Select from '@/components/base/Select'
import TextBlock, { TextBlockContentProps } from '@/components/base/TextBlock'
import Typography from '@/components/base/Typography'

import { useLenis } from '@/contexts/lenis'
import { useStore } from '@/contexts/store'
import type { SectionTheme, WPFile, WPImage, WPLink } from '@/types'

type ProductHeroProps = SectionTheme & {
  image: WPImage
  heading: TextBlockContentProps['heading']
  paragraphRegular: TextBlockContentProps['paragraphRegular']
  buttons: TextBlockContentProps['buttons']
  sheets: ProductModalProps['sheets']
  extra: TextBlockContentProps['caption']
}

type Sheet = {
  year: string
  pdf: WPFile
}

type ProductModalProps = ModalProps & {
  sheets: Sheet[]
}

const ProductModal = ({ sheets, ...props }: ProductModalProps) => {
  const [currentSheet, setCurrentSheet] = useState<Sheet>(sheets[0])
  const { strings } = useStore()

  const findCurrentSheet = useCallback(
    (value: string) =>
      sheets.find((sheet) => sheet.year.toString() === value) as Sheet,
    [sheets]
  )

  useEffect(() => {
    console.log(currentSheet)
  }, [currentSheet])

  return (
    <Modal {...props}>
      <Typography variant="h6" component="h6">
        {strings?.modalDataSheetTitle}
      </Typography>
      <Select
        value={currentSheet.year}
        options={sheets.map((sheet) => ({
          label: sheet.year,
          value: sheet.year
        }))}
        onChange={(event) =>
          setCurrentSheet(findCurrentSheet(event.target.value))
        }
      />
      <div>
        <LinkButton href={currentSheet.pdf.url} variant="contained">
          {strings?.modalDataSheetCta}
        </LinkButton>
      </div>
    </Modal>
  )
}

const ProductHero = ({
  theme,
  image,
  extra,
  sheets,
  buttons,
  ...props
}: ProductHeroProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { lenis } = useLenis()

  useEffect(() => {
    if (!lenis) return
    if (modalOpen) {
      lenis.stop()
    } else {
      lenis.start()
    }
  }, [modalOpen, lenis])

  return (
    <>
      <Section theme={theme}>
        <Container>
          <Grid className="gap-y-spacing-lg">
            <div className="col-span-2 md:col-span-3 lg:col-span-5">
              <div className="aspect-h-1 aspect-w-1 relative md:aspect-h-[622] md:aspect-w-[499]">
                <Image
                  src={image?.sizes?.medium.url}
                  alt={image?.alt}
                  priority={true}
                  fill={true}
                  className="object-contain"
                />
              </div>
            </div>
            <TextBlock
              density="small"
              titleVariant="h2"
              rootClassName="col-span-2 pt-spacing-lg md:col-span-3 lg:col-span-6 lg:col-start-7"
              headingClassName="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-x-gutter"
              headingInnerClassName="col-span-2 md:col-span-3 lg:col-span-5"
              caption={extra}
              buttons={{
                ...buttons,
                secondaryButton: {
                  ...buttons?.secondaryButton,
                  asButton: true
                }
              }}
              onSecondaryButtonClick={(event) => {
                event.preventDefault()
                setModalOpen(true)
              }}
              {...props}
            />
          </Grid>
        </Container>
      </Section>

      {sheets ? (
        <ProductModal
          open={modalOpen}
          sheets={sheets}
          onClose={() => setModalOpen(false)}
        />
      ) : null}
    </>
  )
}

export default ProductHero
