import classNames from 'classnames'

import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import Section from '@/components/base/Section'
import TextBlock, { TextBlockContentProps } from '@/components/base/TextBlock'

import type { SectionTheme } from '@/types'

export type TextProps = SectionTheme &
  TextBlockContentProps &
  Partial<{
    alignment: 'text-left' | 'text-right'
    indented: boolean
    compressed: boolean
  }>

const Text = ({
  theme,
  alignment = 'text-left',
  indented,
  compressed,
  ...props
}: TextProps) => {
  return (
    <Section theme={theme}>
      <Container>
        <Grid>
          <TextBlock
            titleVariant={compressed ? 'h4' : 'h2'}
            density={compressed ? 'small' : 'large'}
            rootClassName={classNames(
              {
                'col-span-2 md:col-span-4 lg:col-span-5': !compressed,
                'col-span-2 md:col-span-5 lg:col-span-7': compressed
              },
              {
                'md:col-start-2 lg:col-start-2':
                  indented && alignment === 'text-left'
              },
              {
                'md:col-start-3 lg:col-start-7':
                  indented && !compressed && alignment === 'text-right',
                'md:col-start-3 lg:col-start-8':
                  !indented && !compressed && alignment === 'text-right'
              },
              {
                'md:col-start-2 lg:col-start-6':
                  !indented && compressed && alignment === 'text-right',
                'md:col-start-1 lg:col-start-5':
                  indented && compressed && alignment === 'text-right'
              }
            )}
            {...props}
          />
        </Grid>
      </Container>
    </Section>
  )
}

export default Text
