import { Fragment } from 'react'

import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import Link from '@/components/base/Link'
import Section from '@/components/base/Section'
import Typography from '@/components/base/Typography'

import type { SectionTheme } from '@/types'

type BreadcrumbsSlice = {
  id: number
  link: string
  title: string
}

type BreadcrumbsProps = SectionTheme & {
  path?: BreadcrumbsSlice[]
}

const Breadcrumbs = ({ theme, path }: BreadcrumbsProps) => {
  return (
    <Section theme={theme}>
      <Container>
        <Grid>
          <div className="col-span-2 md:col-span-6 lg:col-span-12">
            <div className="flex items-center space-x-spacing-xxs">
              {path?.map(({ link, title }, index) => (
                <Fragment key={`breadcrumb-${index}`}>
                  {index < path?.length - 1 ? (
                    <>
                      <Link href={link} className="block">
                        <Typography
                          variant="overline"
                          className="block text-text-overline-light dark:text-text-overline-dark"
                        >
                          {title}
                        </Typography>
                      </Link>
                      <Typography
                        variant="overline"
                        className="block text-text-overline-light dark:text-text-overline-dark"
                      >
                        {'/'}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant="overline"
                      className="block text-text-overline-light dark:text-text-overline-dark"
                    >
                      {title}
                    </Typography>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        </Grid>
      </Container>
    </Section>
  )
}

export default Breadcrumbs
