import Breadcrumbs from '@/components/sections/Breadcrumbs'
import Certifications from '@/components/sections/Certifications'
import ContactForm from '@/components/sections/ContactForm'
import DoubleImage from '@/components/sections/DoubleImage'
import Hero from '@/components/sections/Hero'
import ImageCarousel from '@/components/sections/ImageCarousel'
import ImageGallery from '@/components/sections/ImageGallery'
import ImageText from '@/components/sections/ImageText'
import NewsCard from '@/components/sections/NewsCard'
import Newsletter from '@/components/sections/Newsletter'
import Paragraph from '@/components/sections/Paragraph'
import Percentages from '@/components/sections/Percentages'
import Process from '@/components/sections/Process'
import ProductGrid from '@/components/sections/ProductGrid'
import ProductHero from '@/components/sections/ProductHero'
import ProductList from '@/components/sections/ProductList'
import RelatedCards from '@/components/sections/RelatedCards'
import RelatedProducts from '@/components/sections/RelatedProducts'
import SingleImage from '@/components/sections/SingleImage'
import Spacer from '@/components/sections/Spacer'
import Text from '@/components/sections/Text'

export const sectionsMap: any = {
  breadcrumbs: Breadcrumbs,
  certifications: Certifications,
  contact_form: ContactForm,
  double_image: DoubleImage,
  hero: Hero,
  image_carousel: ImageCarousel,
  image_gallery: ImageGallery,
  image_text: ImageText,
  news_card: NewsCard,
  newsletter: Newsletter,
  paragraph: Paragraph,
  percentages: Percentages,
  process: Process,
  product_grid: ProductGrid,
  product_hero: ProductHero,
  product_list: ProductList,
  related_cards: RelatedCards,
  related_products: RelatedProducts,
  single_image: SingleImage,
  spacer: Spacer,
  text: Text
}
