import { useRef } from 'react'
import classNames from 'classnames'
import { Swiper as SwiperImpl } from 'swiper'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import ImageRatio from '@/components/base/ImageRatio'
import Link from '@/components/base/Link'
import Section from '@/components/base/Section'
import SwiperNavigation from '@/components/base/SwiperNavigation'

import 'swiper/css'

import 'swiper/css/pagination'

import type { SectionTheme, WPImage, WPLink } from '@/types'

type ImageCarouselProps = SectionTheme & {
  items?: Partial<{
    image: Partial<WPImage>
    link: Partial<WPLink>
  }>[]
}

const ImageCarousel = ({ theme, items = [] }: ImageCarouselProps) => {
  const swiper = useRef<SwiperImpl>(null!)

  return (
    <Section theme={theme} className="overflow-hidden">
      <Container className="pt-spacing-lg before:absolute before:bottom-spacing-md before:left-0 before:right-0 before:top-0 before:bg-background-secondary-light dark:before:bg-background-secondary-dark">
        <Grid>
          <div className="col-span-2 md:col-span-6 lg:col-span-12">
            <Swiper
              modules={[Pagination]}
              className="select-none !overflow-visible !pb-spacing-md [--swiper-pagination-bottom:0] [--swiper-pagination-bullet-inactive-color:#1C1917] [--swiper-pagination-bullet-inactive-opacity:1] [--swiper-pagination-color:#1C1917] dark:[--swiper-pagination-bullet-inactive-color:white] dark:[--swiper-pagination-color:white]"
              centerInsufficientSlides={true}
              spaceBetween={20}
              slidesPerView={1}
              slidesPerGroup={1}
              pagination={{
                type: 'bullets',
                enabled: true,
                dynamicBullets: true
              }}
              onBeforeInit={(instance) => {
                swiper.current = instance
              }}
              breakpoints={{
                768: {
                  spaceBetween: 30,
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                  pagination: { enabled: false }
                },
                1024: {
                  spaceBetween: 30,
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                  pagination: { enabled: false }
                }
              }}
            >
              {items.map(({ image, link }, index) => (
                <SwiperSlide key={`slide-${index}`}>
                  {link ? (
                    <Link href={link.url || '/'} aria-label={link.title}>
                      <ImageRatio
                        src={image?.sizes?.large.url || image?.url || ''}
                        alt={image?.alt || ''}
                        ratio="4:5"
                      />
                    </Link>
                  ) : (
                    <ImageRatio
                      src={image?.sizes?.large.url || image?.url || ''}
                      alt={image?.alt || ''}
                      ratio="4:5"
                    />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <SwiperNavigation
            className={classNames(
              'z-10 col-span-2 hidden md:col-span-4 md:col-start-2 md:block lg:col-span-10 lg:col-start-2',
              {
                'md:hidden': items.length < 3,
                'lg:hidden': items.length < 5
              }
            )}
            onPrevClick={() => swiper.current.slidePrev()}
            onNextClick={() => swiper.current.slideNext()}
          />
        </Grid>
      </Container>
    </Section>
  )
}

export default ImageCarousel
