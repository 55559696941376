import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import Link from '@/components/base/Link'
import ProductCard, { Product } from '@/components/base/ProductCard'
import Section from '@/components/base/Section'

import 'swiper/css'

import type { SectionTheme } from '@/types'

type RelatedProductsProps = SectionTheme & {
  items?: Product[]
}

const RelatedProducts = ({ theme, items = [] }: RelatedProductsProps) => {
  return (
    <Section theme={theme} className="overflow-hidden">
      <Container>
        <Grid>
          <div className="col-span-2 md:col-span-6 lg:col-span-12">
            <Swiper
              modules={[Pagination]}
              className="select-none !overflow-visible !pb-spacing-md [--swiper-pagination-bottom:0] [--swiper-pagination-bullet-inactive-color:#1C1917] [--swiper-pagination-bullet-inactive-opacity:1] [--swiper-pagination-color:#1C1917] dark:[--swiper-pagination-bullet-inactive-color:white] dark:[--swiper-pagination-color:white]"
              centerInsufficientSlides={true}
              spaceBetween={20}
              slidesPerView={1}
              slidesPerGroup={1}
              pagination={{
                type: 'bullets',
                enabled: true,
                dynamicBullets: true
              }}
              breakpoints={{
                768: {
                  spaceBetween: 30,
                  slidesPerView: 2,
                  slidesPerGroup: 2
                },
                1024: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                  pagination: { enabled: false }
                }
              }}
            >
              {items.map(({ link, ...product }, index) => (
                <SwiperSlide key={`slide-${index}`}>
                  {link ? (
                    <Link href={link}>
                      <ProductCard {...product} />
                    </Link>
                  ) : (
                    <ProductCard {...product} />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Grid>
      </Container>
    </Section>
  )
}

export default RelatedProducts
