import { sectionsMap } from '@/components/sections'

type BuilderProps = {
  sections?: {
    acfFcLayout: string
  }[]
}

const Builder = ({ sections }: BuilderProps) => {
  return (
    <>
      {sections?.map(({ acfFcLayout, ...section }, index) => {
        const Section = sectionsMap[acfFcLayout]
        return <Section key={`section-${index}`} {...section} />
      })}
    </>
  )
}

export default Builder
