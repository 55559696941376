import { PropsWithChildren, useRef } from 'react'
import { Transition } from 'react-transition-group'
import classNames from 'classnames'

import Portal from '@/components/base/Portal'
import SVGIcon from '@/components/base/SVGIcon'

export type ModalProps = PropsWithChildren<
  Partial<{
    open: boolean
    onClose: () => void
  }>
>

const Modal = ({ open = false, children, onClose }: ModalProps) => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <Portal>
      <Transition
        nodeRef={ref}
        in={open}
        timeout={{
          enter: 0,
          exit: 300
        }}
        unmountOnExit
      >
        {(state) => (
          <section
            ref={ref}
            className={classNames(
              'fixed left-0 top-0 z-modal flex h-full w-full items-center justify-center  p-[2rem] md:p-[4rem] lg:p-[8rem]',
              {
                'opacity-0': state === 'entering',
                'opacity-100 transition-opacity duration-300 ease-in-out-cubic':
                  state === 'entered',
                'opacity-0 transition-opacity duration-300 ease-in-out-cubic':
                  state === 'exiting'
              }
            )}
          >
            <div
              className="absolute left-0 top-0 h-full w-full bg-background-overlay-light dark:bg-background-overlay-dark"
              onClick={onClose}
            />
            <div className="relative grid w-full max-w-[66rem] gap-y-spacing-sm  bg-background-primary-light p-spacing-sm md:gap-y-spacing-lg md:p-spacing-2xl">
              <div>
                <button
                  className="-m-spacing-xs flex p-spacing-xs"
                  onClick={onClose}
                >
                  <SVGIcon
                    symbol="more-icon"
                    className="h-[1.2rem] w-[1.2rem] rotate-45"
                  />
                </button>
              </div>
              {children}
            </div>
          </section>
        )}
      </Transition>
    </Portal>
  )
}

export default Modal
