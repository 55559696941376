import { forwardRef } from 'react'
import classNames from 'classnames'

import AspectRatio, { AspectRatioProps } from '@/components/base/AspectRatio'
import LazyImage, { LazyImageProps } from '@/components/base/LazyImage'

export type ImageRatioProps = LazyImageProps &
  Partial<Pick<AspectRatioProps, 'ratio'> & { fit: 'cover' | 'contain' }>

const ImageRatio = forwardRef<HTMLImageElement, ImageRatioProps>(
  ({ ratio = '1:1', fit = 'cover', ...props }, ref) => {
    return (
      <AspectRatio ratio={ratio}>
        <LazyImage
          ref={ref}
          className={classNames({
            'object-cover object-center': fit === 'cover',
            'object-contain object-center': fit === 'contain'
          })}
          fill
          {...props}
        />
      </AspectRatio>
    )
  }
)

export default ImageRatio
