import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import ImageParallax from '@/components/base/ImageParallax'
import ImageRatio, { ImageRatioProps } from '@/components/base/ImageRatio'
import Section from '@/components/base/Section'

import type { SectionTheme, WPImage } from '@/types'

type DoubleImageProps = SectionTheme &
  Partial<{
    leftImage: WPImage
    leftRatio: ImageRatioProps['ratio']
    rightImage: WPImage
    rightRatio: ImageRatioProps['ratio']
  }>

const DoubleImage = ({
  theme,
  leftImage,
  leftRatio,
  rightImage,
  rightRatio
}: DoubleImageProps) => {
  return (
    <Section theme={theme}>
      <Container>
        <Grid className="gap-y-spacing-xs">
          <div className="col-span-2 md:col-span-3 lg:col-span-5 lg:col-start-2">
            {leftImage ? (
              <ImageParallax>
                {(ref) => (
                  <ImageRatio
                    ref={ref}
                    src={leftImage.sizes?.medium.url || leftImage.url || ''}
                    alt={leftImage.alt || ''}
                    ratio={leftRatio}
                  />
                )}
              </ImageParallax>
            ) : null}
          </div>
          <div className="col-span-2 md:col-span-3 lg:col-span-5">
            {rightImage ? (
              <ImageParallax>
                {(ref) => (
                  <ImageRatio
                    ref={ref}
                    src={rightImage.sizes?.medium.url || rightImage.url || ''}
                    alt={rightImage.alt || ''}
                    ratio={rightRatio}
                  />
                )}
              </ImageParallax>
            ) : null}
          </div>
        </Grid>
      </Container>
    </Section>
  )
}

export default DoubleImage
