import classNames from 'classnames'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Container from '@/components/base/Container'
import Link from '@/components/base/Link'
import ProductCard, { Product } from '@/components/base/ProductCard'
import Section from '@/components/base/Section'

import type { SectionTheme } from '@/types'

type ProductGridProps = SectionTheme & {
  items?: Product[]
}

type ProductSwiperGridProps = Pick<ProductGridProps, 'items'> & {
  className?: string
}

type ProductCardsGridProps = Pick<ProductGridProps, 'items'> & {
  className?: string
}

const ProductSwiperGrid = ({ items, className }: ProductSwiperGridProps) => {
  return (
    <div
      className={classNames(
        'h-auto w-full grid-cols-2 gap-x-gutter overflow-hidden md:grid-cols-6 lg:grid-cols-12',
        className
      )}
    >
      <div className="col-span-2 md:col-span-6 lg:col-span-12">
        <Swiper
          modules={[Pagination]}
          className="select-none !overflow-visible !pb-spacing-md [--swiper-pagination-bottom:0] [--swiper-pagination-bullet-inactive-color:#1C1917] [--swiper-pagination-bullet-inactive-opacity:1] [--swiper-pagination-color:#1C1917] dark:[--swiper-pagination-bullet-inactive-color:white] dark:[--swiper-pagination-color:white]"
          centerInsufficientSlides={true}
          spaceBetween={20}
          slidesPerView={1}
          slidesPerGroup={1}
          pagination={{
            type: 'bullets',
            enabled: true,
            dynamicBullets: true
          }}
          breakpoints={{
            768: {
              spaceBetween: 30,
              slidesPerView: 2,
              slidesPerGroup: 2
            },
            1024: {
              spaceBetween: 30,
              slidesPerView: 3,
              slidesPerGroup: 3,
              pagination: { enabled: false }
            }
          }}
        >
          {items?.map(({ link, ...product }, index) => (
            <SwiperSlide key={`slide-${index}`}>
              {link ? (
                <Link href={link}>
                  <ProductCard {...product} />
                </Link>
              ) : (
                <ProductCard {...product} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

const ProductCardsGrid = ({ items, className }: ProductCardsGridProps) => {
  return (
    <div
      className={classNames(
        'h-auto w-full grid-cols-2 gap-x-gutter gap-y-spacing-sm md:grid-cols-6 md:gap-y-gutter lg:grid-cols-12',
        className
      )}
    >
      {items?.map(({ link, ...product }, index) => (
        <div
          key={`product-${index}`}
          className="col-span-2 will-change-transform md:col-span-3 lg:col-span-4"
        >
          {link ? (
            <Link href={link}>
              <ProductCard {...product} />
            </Link>
          ) : (
            <ProductCard {...product} />
          )}
        </div>
      ))}
    </div>
  )
}

const ProductGrid = ({ theme, items = [] }: ProductGridProps) => {
  return (
    <Section theme={theme}>
      <Container>
        <ProductSwiperGrid items={items} className="grid md:hidden" />
        <ProductCardsGrid items={items} className="hidden md:grid" />
      </Container>
    </Section>
  )
}

export default ProductGrid
