import gsap from 'gsap'

import { Animation } from '@/types'

export const percentagesRevealAnimation = ({
  node,
  reverse,
  context
}: Animation) => {
  const selector = context.selector || gsap.utils.selector(node)

  const value = selector('[data-motion-value]')[0]
  const bar = selector('[data-motion-bar]')

  const percentageTween = { value: 0 }
  const percentage = value.getAttribute('data-percentage')

  const scrollTrigger = {
    trigger: node,
    once: !reverse,
    start: 'top 92%', // start: 'top bottom',
    markers: false,
    toggleActions: `play none none ${reverse ? 'reverse' : 'none'}`
  }

  gsap
    .timeline({
      scrollTrigger
    })
    .fromTo(
      percentageTween,
      { value: 0 },
      {
        value: percentage,
        duration: 3,
        ease: 'power2.out',
        onUpdate: () => {
          value.innerHTML = percentageTween.value.toFixed(
            percentage % 1 > 0 ? 1 : 0
          )
        }
      }
    )
    .fromTo(
      bar,
      { scaleX: 0 },
      { scaleX: 1, transformOrigin: 'left', duration: 3, ease: 'power2.out' },
      '<'
    )
}
