import classNames from 'classnames'

import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import Section from '@/components/base/Section'
import TextBlock, { TextBlockContentProps } from '@/components/base/TextBlock'

import type { SectionTheme } from '@/types'

type ParagraphProps = SectionTheme &
  Pick<
    TextBlockContentProps,
    'doubleParagraph' | 'paragraphRegular' | 'paragraphSmall' | 'buttons'
  >

const Paragraph = ({ theme, doubleParagraph, ...props }: ParagraphProps) => {
  return (
    <Section theme={theme}>
      <Container>
        <Grid>
          <TextBlock
            doubleParagraph={doubleParagraph}
            rootClassName="col-span-2 md:col-span-6 lg:col-span-12"
            paragraphsClassName={classNames(
              'grid-cols-2 md:grid-cols-6 lg:grid-cols-12'
            )}
            paragraphRegularClassName={classNames({
              'col-span-2 md:col-span-6 lg:col-span-12': !doubleParagraph,
              'col-span-2 md:col-span-3 lg:col-span-6': doubleParagraph
            })}
            paragraphSmallClassName={classNames({
              'col-span-2 md:col-span-6 lg:col-span-12': !doubleParagraph,
              'col-span-2 md:col-span-3 lg:col-span-6': doubleParagraph
            })}
            {...props}
          />
        </Grid>
      </Container>
    </Section>
  )
}

export default Paragraph
