import { Fragment } from 'react'

import ImageText, { ImageTextProps } from '@/components/sections/ImageText'
import Spacer from '@/components/sections/Spacer'

type CertificationsProps = {
  items?: Omit<
    ImageTextProps,
    | 'theme'
    | 'density'
    | 'alignment'
    | 'imageRatio'
    | 'titleVariant'
    | 'doubleParagraph'
    | 'backgroundImage'
    | 'smallImage'
  >[]
}

const Certifications = ({ items = [] }: CertificationsProps) => {
  return (
    <>
      <Spacer theme="dark" variant="2xl" />
      {items.map((item, index) => (
        <Fragment key={`item-${index}`}>
          <ImageText
            key={`item-${index}`}
            theme="dark"
            alignment={index % 2 === 0 ? 'text-right' : 'text-left'}
            doubleParagraph={false}
            backgroundImage={false}
            smallImage={true}
            textShrink={true}
            titleVariant="h4"
            imageRatio="16:9"
            density="small"
            {...item}
          />
          <Spacer theme="dark" variant="2xl" />
        </Fragment>
      ))}
    </>
  )
}

export default Certifications
