import classNames from 'classnames'

import Container from '@/components/base/Container'
import Motion from '@/components/base/Motion'
import Section from '@/components/base/Section'
import Typography from '@/components/base/Typography'

import { percentagesRevealAnimation } from './animations/percentages'
import type { SectionTheme } from '@/types'

type PercentagesProps = SectionTheme & {
  items?: {
    name: string
    percentage: number
  }[]
}

const Percentages = ({ theme, items }: PercentagesProps) => {
  return (
    <Section theme={theme}>
      <Container>
        <div
          className={classNames('grid gap-x-gutter gap-y-spacing-xxs', {
            'grid-cols-1 md:grid-cols-1': items?.length === 1,
            'grid-cols-1 md:grid-cols-2': items?.length === 2,
            'grid-cols-1 md:grid-cols-3': items?.length === 3,
            'grid-cols-1 md:grid-cols-4': items?.length === 4
          })}
        >
          {items?.map(({ name, percentage }, index) => (
            <Motion
              animation={percentagesRevealAnimation}
              key={`percentage-${index}`}
            >
              {({ motionRef }) => (
                <div
                  ref={motionRef}
                  className="col-span-1 grid gap-y-spacing-lg border border-border-thumb-grid-light p-spacing-lg text-center md:px-spacing-sm md:py-spacing-lg lg:p-spacing-lg dark:border-border-thumb-grid-dark"
                >
                  <div className="flex items-center justify-center space-x-spacing-xs">
                    <Typography
                      variant="h1"
                      component="h3"
                      className="text-text-primary-light dark:text-text-primary-dark"
                      data-percentage={percentage}
                      data-motion-value
                    >
                      {`${percentage}`}
                    </Typography>
                    <Typography
                      variant="h6"
                      className="text-text-primary-light dark:text-text-primary-dark"
                    >
                      {`%`}
                    </Typography>
                  </div>
                  <div className="relative h-[.4rem] w-full bg-border-thumb-grid-light dark:bg-border-thumb-grid-dark">
                    <div
                      className="absolute left-0 top-0 h-full bg-surface-primay-light dark:bg-surface-primay-dark"
                      style={{ width: `${percentage}%` }}
                      data-motion-bar
                    />
                  </div>
                  <Typography
                    paragraph
                    variant="regular"
                    className="text-text-secondary-light dark:text-text-secondary-dark"
                  >
                    {name}
                  </Typography>
                </div>
              )}
            </Motion>
          ))}
        </div>
      </Container>
    </Section>
  )
}

export default Percentages
