import { PropsWithChildren } from 'react'
import classNames from 'classnames'

export type AspectRatioProps = PropsWithChildren<{
  ratio?: '4:5' | '1:1' | '16:9'
}>

const AspectRatio = ({ ratio = '1:1', children }: AspectRatioProps) => {
  return (
    <span
      className={classNames('relative block', {
        'aspect-h-5	aspect-w-4': ratio === '4:5',
        'aspect-h-1 aspect-w-1': ratio === '1:1',
        'aspect-h-9 aspect-w-16': ratio === '16:9'
      })}
    >
      {children}
    </span>
  )
}

export default AspectRatio
