import { ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'

import Spinner from '@/components/base/Spinner'
import Typography from '@/components/base/Typography'

export type SubmitButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  Partial<{
    variant: 'contained' | 'outlined' | 'rounded'
    selected: boolean
    fetching: boolean
  }>

const SubmitButton = ({
  variant = 'contained',
  children,
  className,
  selected = false,
  disabled,
  fetching,
  ...props
}: SubmitButtonProps) => {
  const variantClasses = classNames(
    'flex items-center p-[1.2rem]',
    {
      'border border-surface-primay-light bg-surface-primay-light text-text-button-primary-light dark:border-surface-primay-dark dark:bg-surface-primay-dark dark:text-text-button-primary-dark':
        variant === 'contained'
    },
    {
      'border border-border-primary-light text-text-button-secondary-light dark:border-border-primary-dark dark:text-text-button-secondary-dark':
        variant === 'outlined'
    },
    {
      'rounded-radius-minimal border border-transparent text-text-button-secondary-light dark:text-text-button-secondary-dark':
        variant === 'rounded',
      'bg-surface-button-filter-selected-light dark:bg-surface-button-filter-selected-dark':
        variant === 'rounded' && selected
    }
  )

  return (
    <button
      {...props}
      type="submit"
      disabled={disabled}
      className={classNames(
        'group relative',
        variantClasses,
        { 'pointer-events-none': disabled },
        className
      )}
    >
      <span
        data-label={children as string}
        className={classNames(
          'relative block overflow-hidden p-[.4rem] after:absolute after:left-0 after:top-0 after:-mb-[0.35em] after:-mt-[0.3em] after:w-full after:translate-y-full after:p-[.4rem] after:font-roboto after:text-small after:transition-transform after:duration-300 after:ease-out-cubic after:content-[attr(data-label)] pointer:group-hover:after:translate-y-0',
          { invisible: fetching }
        )}
      >
        <Typography
          variant="small"
          className={classNames(
            'block transition-transform duration-300 ease-out-cubic pointer:group-hover:-translate-y-full',
            { 'opacity-40': disabled }
          )}
        >
          {children}
        </Typography>
      </span>
      {fetching ? (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <Spinner variant="small" />
        </div>
      ) : null}
    </button>
  )
}

export default SubmitButton
