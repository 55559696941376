import Builder from '@/components/layout/Builder'
import usePagePrecache from '@/hooks/usePagePrecache'

import { getPageBuilderPrecacheMedia } from './utils'
import { WPPageData } from '@/types'

type PageBuilderProps = {
  pageData: WPPageData
}

const PageBuilder = ({ pageData }: PageBuilderProps) => {
  usePagePrecache(true, getPageBuilderPrecacheMedia(pageData))

  return <Builder sections={pageData.acf?.builder} />
}

export default PageBuilder
