import { Media } from '@/utils/media'
import { pageHasHero, pageHasHeroBanner } from '@/utils/page'

import { WPPageData } from '@/types'

export const getPageBuilderPrecacheMedia = (pageData: WPPageData) => {
  return [
    ...(pageHasHero(pageData)
      ? [
          {
            src:
              pageData.acf.hero?.posterMobile?.sizes?.large.url ||
              pageData.acf.hero?.posterMobile?.url ||
              '',
            type: 'image'
          },
          {
            src:
              pageData.acf.hero?.posterDesktop?.sizes?.large.url ||
              pageData.acf.hero?.posterDesktop?.url ||
              '',
            type: 'image'
          }
        ]
      : ([] as Media[])),
    ...(pageHasHeroBanner(pageData)
      ? [
          {
            src:
              pageData.acf.hero.banner?.items[0].image?.sizes?.thumbnail.url ||
              pageData.acf.hero.banner?.items[0].image?.url ||
              '',
            type: 'image'
          }
        ]
      : ([] as Media[]))
  ] as Media[]
}

export const getFeaturedImagePrecacheMedia = (
  pageData: WPPageData,
  size: 'thumbnail' | 'medium' | 'large'
) => {
  return [
    {
      src: pageData.featured_image?.sizes?.[size].url,
      type: 'image'
    }
  ] as Media[]
}
