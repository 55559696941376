import { boolean, mixed, object, ref, string } from 'yup'

import { WPCF7FormField } from '@/types'

export const createYupSchema = (config: WPCF7FormField[], lang = 'en') => {
  const errors = require(`@/utils/form/errors/${lang}.json`) // eslint-disable-line
  const schema = config
    .filter(
      (c) =>
        c.type !== 'space' && c.type !== 'title' && c.type !== 'multiple_dates'
    )
    .reduce((obj, item) => {
      let value: any = {}
      if (item.type === 'date' || item.name === 'date') {
        value = string()
        if (item.required) {
          value = value.required(errors['required'])
          value = value.test('date', errors['date'], (val: string) => {
            const regex =
              /^(19[1-9]\d|20\d{2})-(0[1-9]|1[0-2])-([0-2]\d|3[01])$/
            return regex.test(val)
          })
        }
      }
      if (
        item.type === 'text' ||
        item.type === 'textarea' ||
        item.type === 'select' ||
        item.type === 'radio' ||
        item.type === 'hidden'
      ) {
        value = string()
        if (item.required) value = value.required(errors['required'])
      }
      if (item.type === 'email') {
        value = string().email(errors['email'])
        if (item.required) value = value.required(errors['required'])
      }
      if (item.type === 'password') {
        value = string()
        if (item.required)
          value = value.min(8, errors['password']).required(errors['required'])
      }
      if (item.type === 'confirm_password') {
        value = string()
        if (item.required)
          value = value
            .required(errors['required'])
            .oneOf([ref('user_pass'), null], errors['confirm_password'])
      }
      if (item.type === 'tel') {
        value = string().typeError(errors['tel'])
        if (item.required) value = value.required(errors['required'])
      }
      if (item.type === 'checkbox') {
        value = boolean()
        if (item.required)
          value = value
            .oneOf([true], errors['checkbox'])
            .required(errors['required'])
      }
      if (item.type === 'file') {
        value = mixed()
        if (item.required) value = value.required(errors['required'])
      }
      if (item.name === 'codice_fiscale') {
        value = string()
        if (item.required) value = value.required(errors['required'])
        value = value.test(
          'codice_fiscale',
          errors['codice_fiscale'],
          (val: string) => {
            const regex = /^[A-Za-z]{6}\d{2}[A-Za-z]\d{2}[A-Za-z]\d{3}[A-Za-z]$/
            return regex.test(val)
          }
        )
      }

      return {
        ...obj,
        [item.name]: value
      }
    }, {})

  return object().shape(schema)
}

export const createInitialValues = (
  fields: WPCF7FormField[],
  initialValues = []
) => {
  const values = fields.reduce((obj, item) => {
    return {
      ...obj,
      [item.name]: item.type === 'checkbox' ? false : ''
    }
  }, {})
  return Object.assign(values, ...initialValues, { _wpcf7_unit_tag: 'true' })
}

export const convertJsontoFormData = (obj: any) => {
  const formData = new FormData()

  for (const key in obj) {
    // eslint-disable-line no-restricted-syntax
    if (obj.hasOwnProperty(key)) {
      // eslint-disable-line no-prototype-builtins
      formData.append(key, obj[key])
    }
  }
  return formData
}
