import { forwardRef, ReactNode } from 'react'
import classNames from 'classnames'

import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import ImageParallax from '@/components/base/ImageParallax'
import ImageRatio, { ImageRatioProps } from '@/components/base/ImageRatio'
import LazyImage from '@/components/base/LazyImage'
import Link from '@/components/base/Link'
import Section from '@/components/base/Section'
import TextBlock, {
  TextBlockContentProps,
  TextBlockSettingProps
} from '@/components/base/TextBlock'

import type { SectionTheme, WPImage } from '@/types'

export type ImageTextProps = SectionTheme &
  TextBlockContentProps &
  Pick<TextBlockSettingProps, 'density' | 'titleVariant' | 'titleLink'> &
  Partial<{
    image: Partial<WPImage>
    imageRatio: ImageRatioProps['ratio']
    alignment: 'text-left' | 'text-right'
    smallImage: boolean
    imageLink: boolean
    backgroundImage: boolean
    textShrink: boolean
    className: string
  }>

const ImageText = forwardRef<HTMLElement, ImageTextProps>(
  (
    {
      theme,
      image,
      imageRatio,
      alignment = 'text-left',
      smallImage,
      textShrink = false,
      imageLink = false,
      backgroundImage,
      doubleParagraph,
      titleVariant,
      density,
      className,
      ...props
    },
    ref
  ) => {
    const renderSmallImage = (image: ReactNode) => {
      return imageLink ? (
        <Link
          href={props.buttons?.secondaryButton.url}
          aria-label={props.buttons?.secondaryButton.title}
          className="group block"
        >
          {image}
        </Link>
      ) : (
        image
      )
    }

    return (
      <Section
        ref={ref}
        theme={theme}
        className={classNames(
          'relative',
          {
            'flex h-full items-end': backgroundImage && !smallImage
          },
          className
        )}
      >
        <Container>
          <Grid
            className={classNames('gap-y-spacing-lg', {
              'lg:relative': backgroundImage && smallImage,
              'py-spacing-lg md:py-d-spacer-md lg:py-d-spacer-2xl':
                backgroundImage
            })}
          >
            {backgroundImage ? (
              <div
                className={classNames(
                  'absolute left-0 top-0 h-full w-full bg-black-light',
                  {
                    'after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-black-light/40':
                      backgroundImage
                  }
                )}
              >
                <ImageParallax fill>
                  {(ref) => (
                    <LazyImage
                      ref={ref}
                      src={image?.sizes?.large.url || image?.url || ''}
                      alt={image?.alt || ''}
                      fill
                      className="object-cover object-center"
                    />
                  )}
                </ImageParallax>
              </div>
            ) : (
              <div
                className={classNames(
                  {
                    'md:order-1': alignment === 'text-right',
                    'md:order-2': alignment === 'text-left'
                  },
                  {
                    'col-span-2 md:col-span-3 lg:col-span-5': !smallImage,
                    'col-span-2 md:col-span-2 lg:col-span-4': smallImage
                  },
                  {
                    'lg:col-start-8': !smallImage && alignment === 'text-left',
                    'lg:col-start-9': smallImage && alignment === 'text-left'
                  }
                )}
              >
                {renderSmallImage(
                  <ImageParallax>
                    {(ref) => (
                      <ImageRatio
                        ref={ref}
                        src={image?.sizes?.medium.url || image?.url || ''}
                        alt={image?.alt || ''}
                        ratio={imageRatio}
                      />
                    )}
                  </ImageParallax>
                )}
              </div>
            )}

            <TextBlock
              density={density || 'medium'}
              doubleParagraph={doubleParagraph}
              titleVariant={titleVariant || backgroundImage ? 'h4' : 'h2'}
              rootClassName={classNames(
                'flex flex-col',
                { dark: backgroundImage },
                {
                  'md:order-2': alignment === 'text-right',
                  'md:order-1': alignment === 'text-left'
                },
                {
                  'col-span-2 md:col-span-3 lg:col-span-5':
                    !smallImage && !backgroundImage,
                  'col-span-2 md:col-span-4 lg:col-span-6': smallImage,
                  'col-span-2 md:col-span-5 lg:col-span-6':
                    !smallImage && backgroundImage && !doubleParagraph,
                  'col-span-2 md:col-span-5 lg:col-span-7':
                    !smallImage && backgroundImage && doubleParagraph
                },
                {
                  'lg:col-start-8':
                    !smallImage &&
                    !backgroundImage &&
                    alignment === 'text-right',
                  'lg:col-start-7':
                    !smallImage &&
                    backgroundImage &&
                    !doubleParagraph &&
                    alignment === 'text-right',
                  'lg:col-start-6':
                    alignment === 'text-right' &&
                    (smallImage || (backgroundImage && doubleParagraph)),
                  'lg:col-start-2': smallImage && alignment === 'text-left'
                },
                {
                  'justify-center':
                    !props.buttons?.primaryButton &&
                    !props.buttons?.secondaryButton &&
                    !props.paragraphRegular &&
                    !props.paragraphSmall &&
                    !textShrink
                }
                /* {
                  'justify-between':
                    (props.buttons?.primaryButton ||
                      props.buttons?.secondaryButton ||
                      props.paragraphRegular ||
                      props.paragraphSmall) &&
                    !textShrink
                } */
              )}
              headingClassName={classNames({
                'grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-x-gutter':
                  smallImage
              })}
              headingInnerClassName={classNames({
                'col-span-2 md:col-span-4 lg:col-span-5': smallImage
              })}
              paragraphsClassName={classNames({
                'grid-cols-2 md:grid-cols-3 lg:grid-cols-5': !smallImage,
                'grid-cols-2 md:grid-cols-4 lg:grid-cols-6': smallImage,
                'grid-cols-2 md:grid-cols-5 lg:grid-cols-6':
                  !smallImage && backgroundImage && !doubleParagraph,
                'grid-cols-2 md:grid-cols-5 lg:grid-cols-7':
                  !smallImage && backgroundImage && doubleParagraph
              })}
              paragraphRegularClassName={classNames(
                ' text-text-secondary-light dark:text-text-secondary-dark',
                {
                  'col-span-2 md:col-span-3 lg:col-span-5':
                    !smallImage && !backgroundImage && !doubleParagraph,
                  'col-span-2 md:col-span-3':
                    !smallImage && !backgroundImage && doubleParagraph
                },
                {
                  'col-span-2 md:col-span-4 lg:col-span-6':
                    smallImage && !doubleParagraph,
                  'col-span-2 md:col-span-4': smallImage && doubleParagraph
                },
                {
                  'col-span-2 md:col-span-5 lg:col-span-6':
                    !smallImage && backgroundImage && !doubleParagraph,
                  'col-span-2 md:col-span-3 lg:col-span-5':
                    !smallImage && backgroundImage && doubleParagraph
                }
              )}
              paragraphSmallClassName={classNames(
                'col-span-2 text-text-secondary-light dark:text-text-secondary-dark',
                {
                  'md:col-span-3 lg:col-span-2':
                    !smallImage && !backgroundImage,
                  'md:col-span-4 lg:col-span-2': smallImage
                }
              )}
              {...props}
            />
          </Grid>
        </Container>
      </Section>
    )
  }
)

export default ImageText
