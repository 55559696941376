/* eslint-disable no-unused-vars */
import { RefObject, useRef } from 'react'
import { useGSAP } from '@gsap/react'

import { useLenis } from '@/contexts/lenis'
import { Animation } from '@/types'

type MotionState = {
  motionRef: RefObject<HTMLDivElement>
  motionClasses: string
}

type MotionProps = {
  children: (state: MotionState) => React.ReactNode
  animation: (props: Animation<HTMLElement>) => void // gsap.core.Timeline
}

const Motion = ({ animation, children }: MotionProps) => {
  const root = useRef<HTMLDivElement>(null!)
  const { lenis } = useLenis()

  useGSAP(
    (context) => {
      if (!lenis) return
      animation({
        node: root.current,
        reverse: false,
        context
      })
    },
    { dependencies: [lenis], scope: root }
  )

  return children({
    motionRef: root,
    motionClasses: ''
  })
}

export default Motion
