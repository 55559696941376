import { useMemo, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import { useGSAP } from '@gsap/react'
import classNames from 'classnames'
import gsap from 'gsap'

import ImageRatio from '@/components/base/ImageRatio'
import Link from '@/components/base/Link'
import Typography from '@/components/base/Typography'

import { TRANSITION_LEAVE_DURATION } from '@/contexts/transition'
import type { WPImage, WPLink } from '@/types'

type BannerCard = Partial<{
  image: Partial<WPImage>
  link: Partial<WPLink>
  title: string
  subtitle: string
}>

export type BannerProps = Partial<{
  className: string
  items: BannerCard[]
}>

type BannerCardProps = BannerCard & { active: boolean; className?: string }

const BannerCard = ({
  image,
  link,
  title,
  subtitle,
  active,
  className
}: BannerCardProps) => {
  const root = useRef<HTMLAnchorElement>(null)

  const { context, contextSafe } = useGSAP(() => makeTimeline(), {
    scope: root
  })

  const makeTimeline = contextSafe((done?: () => void) => {
    const selector = context.selector || gsap.utils.selector(root.current)
    const image = selector('[data-motion-image]') as HTMLElement
    const title = selector('[data-motion-title]') as HTMLElement
    const subtitle = selector('[data-motion-subtitle]') as HTMLElement

    const timeline = gsap.timeline({
      defaults: { duration: done ? 0.8 : 0, ease: 'power3.inOut' },
      onComplete: done
    })

    active
      ? timeline
          .fromTo(image, { xPercent: 100 }, { xPercent: 0 })
          .fromTo(
            [title, subtitle],
            { yPercent: 120, opacity: 0 },
            { yPercent: 0, opacity: 1, stagger: 0.1 },
            '<'
          )
      : timeline
          .to(image, { xPercent: -100 })
          .to(
            [title, subtitle],
            { yPercent: -120, opacity: 0, stagger: 0.1 },
            '<'
          )
  })

  return (
    <Transition in={active} nodeRef={root} addEndListener={makeTimeline}>
      <Link
        ref={root}
        href={link?.url || ''}
        className={classNames(
          'flex space-x-spacing-sm pr-spacing-xl',
          className
        )}
      >
        <span className="block h-[10rem] w-[10rem] shrink-0 overflow-hidden">
          <span className="block will-change-transform" data-motion-image>
            {image ? (
              <ImageRatio
                src={image.sizes?.thumbnail.url || image.url || ''}
                alt={image.alt || ''}
                priority={true}
                loading="eager"
                ratio="1:1"
                fit="cover"
              />
            ) : null}
          </span>
        </span>
        <span className="flex grow flex-col justify-center space-y-spacing-xxs">
          {title ? (
            <span className="flex overflow-hidden py-[.4rem]">
              <Typography
                paragraph
                variant="regular"
                className="text-text-primary-light opacity-0 will-change-transform dark:text-text-primary-dark"
                data-motion-title
              >
                {title}
              </Typography>
            </span>
          ) : null}

          {subtitle ? (
            <span className="flex overflow-hidden py-[.4rem]">
              <Typography
                paragraph
                variant="small"
                className="text-text-secondary-light opacity-0 will-change-transform dark:text-text-secondary-dark"
                data-motion-subtitle
              >
                {subtitle}
              </Typography>
            </span>
          ) : null}
        </span>
      </Link>
    </Transition>
  )
}

const BannerProgress = ({ onChange }: { onChange?: () => void }) => {
  const root = useRef<SVGSVGElement>(null!)
  const circle = useRef<SVGCircleElement>(null!)
  const length = useMemo(() => 43.69887924194336, [])
  const progress = useMemo(() => ({ value: 0 }), [])
  const timeline = useRef<gsap.core.Timeline>(null!)

  useGSAP(
    () => {
      timeline.current?.kill()
      timeline.current = gsap
        .timeline({
          repeat: -1,
          delay: TRANSITION_LEAVE_DURATION,
          onUpdate: () => {
            circle.current.style.strokeDashoffset = progress.value.toString()
          }
        })
        .fromTo(
          progress,
          { value: length },
          {
            value: 0,
            duration: 5,
            ease: 'none',
            onComplete: () => {
              if (onChange) {
                onChange()
              }
            }
          }
        )
        .to(progress, { value: -length, duration: 1, ease: 'power3.out' })

      return () => {
        timeline.current?.kill()
      }
    },
    { scope: root }
  )

  return (
    <svg
      ref={root}
      width="16"
      height="16"
      viewBox="-1 -1 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="-rotate-90"
    >
      <circle
        r="7"
        cx="7"
        cy="7"
        strokeWidth="2"
        strokeDashoffset="0"
        strokeDasharray={length}
        className="fill-transparent stroke-surface-primay-light/20"
      />
      <circle
        ref={circle}
        r="7"
        cx="7"
        cy="7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDashoffset={length}
        strokeDasharray={length}
        className="fill-transparent stroke-surface-primay-light"
      />
    </svg>
  )
}

const Banner = ({ items = [], className }: BannerProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <div className={classNames('relative', className)}>
      <div className="relative bg-background-primary-light dark:bg-background-primary-dark">
        {items.map((card, index) => (
          <BannerCard
            key={`card-${index}`}
            active={index === currentIndex}
            className={classNames({
              relative: index == 0,
              'absolute left-0 top-0 h-full w-full': index > 0,
              'z-10': index === currentIndex,
              'z-0': index !== currentIndex
            })}
            {...card}
          />
        ))}
        {items.length > 1 ? (
          <div className="absolute right-spacing-sm top-1/2 -translate-y-1/2">
            <BannerProgress
              onChange={() => {
                setCurrentIndex((currentIndex) =>
                  currentIndex === items.length - 1 ? 0 : currentIndex + 1
                )
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Banner
