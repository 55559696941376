import { forwardRef } from 'react'
import classNames from 'classnames'

import SpacerComponent, {
  SpacerProps as SpacerComponentProps
} from '@/components/base/Spacer'

import type { SectionTheme } from '@/types'

type SpacerProps = SectionTheme & SpacerComponentProps

const Spacer = forwardRef<HTMLHRElement, SpacerProps>(
  ({ theme = 'light', ...props }, ref) => {
    return (
      <SpacerComponent
        ref={ref}
        className={classNames({ dark: theme === 'dark' })}
        {...props}
      />
    )
  }
)

export default Spacer
