import ImageRatio from '@/components/base/ImageRatio'
import Typography from '@/components/base/Typography'

import { WPImage } from '@/types'

export type Product = Partial<{
  link: string
  featuredImage: Partial<WPImage>
  title: {
    rendered: string
  }
}>

const ProductCard = ({ featuredImage, title }: Product) => {
  return (
    <span className="grid gap-y-spacing-md border border-border-thumb-grid-light py-spacing-sm dark:border-border-thumb-grid-dark">
      <ImageRatio
        src={featuredImage?.sizes?.large.url || featuredImage?.url || ''}
        alt={featuredImage?.alt || ''}
        ratio="4:5"
        fit="contain"
      />
      {title ? (
        <Typography
          variant="small"
          className="flex justify-center text-text-secondary-light dark:text-text-secondary-dark"
        >
          {title.rendered}
        </Typography>
      ) : null}
    </span>
  )
}

export default ProductCard
