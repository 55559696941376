import {
  ElementType,
  InputHTMLAttributes,
  useCallback,
  useMemo,
  useState
} from 'react'
import DatePicker from 'react-datepicker'
import { useMedia } from 'react-use'
import classNames from 'classnames'
import { format } from 'date-fns'
import * as dateFnsLocales from 'date-fns/locale'

import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'
import { useLocaleStore } from '@/store/locale'
import { MediaQuery } from '@/constants'

import { WPCF7FormField } from '@/types'

type FormControlProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'onBlur'
> &
  WPCF7FormField & {
    error?: boolean
    options?: string[]
    // eslint-disable-next-line no-unused-vars
    setFieldValue?: (field: string, value: any) => void
    // eslint-disable-next-line no-unused-vars
    onChange?: (e: any) => void
    // eslint-disable-next-line no-unused-vars
    onBlur?: (e: any) => void
  }

type SelectControlProps = Omit<FormControlProps, 'type'>

type DateControlProps = Omit<FormControlProps, 'type'>

const DateControl = ({
  id,
  name,
  value,
  placeholder,
  required,
  disabled,
  error,
  setFieldValue,
  onBlur
}: DateControlProps) => {
  const [focus, setFocus] = useState(false)
  const [displayDate, setDisplayDate] = useState<string>('')
  const currentDate = useMemo(() => (value ? new Date(value) : null), [value])
  const currentLocale = useLocaleStore<any>((state) => state.currentLocale)
  const locale = useMemo<any>(
    () =>
      (dateFnsLocales as any)[currentLocale === 'en' ? 'enUS' : currentLocale],
    [currentLocale]
  )
  const dateFormat = useMemo(
    () => locale?.formatLong.date({ width: 'short' }),
    [locale]
  )
  const isTouch = useMedia(MediaQuery.TOUCH, false)

  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (!date) return
      setDisplayDate(format(date, 'P', { locale }))
      setFieldValue && setFieldValue('date', format(date, 'yyyy-MM-dd'))
    },
    [locale, setFieldValue]
  )

  return isTouch ? (
    <div className="relative">
      <button
        tabIndex={-1}
        className={classNames(
          'pointer-events-none flex h-[4.8rem] w-full items-center justify-between border bg-transparent px-spacing-xs font-roboto text-regular outline-none focus:ring-2',
          'text-text-secondary-light dark:text-text-secondary-dark',
          {
            'ring-2 ring-text-overline-light/20': focus && !error,
            'border-error ring-2 ring-error/50': focus && error
          },
          {
            'border-border-primary-light focus:ring-text-overline-light/20':
              !error,
            'border-error focus:ring-error/50': error
          }
        )}
      >
        <Typography variant="regular" className="!m-0">
          {displayDate || placeholder}
        </Typography>
        <SVGIcon
          symbol="calendar-icon"
          className="h-[1.6rem] w-[1.6rem] fill-text-secondary-light p-[.1rem] dark:fill-text-secondary-dark"
        />
      </button>
      <input
        type="date"
        id={id}
        name={name}
        value={value}
        required={required}
        disabled={disabled}
        className="absolute left-0 top-0 block h-full w-full px-spacing-xs opacity-0"
        onChange={(event) => handleDateChange(new Date(event.target.value))}
        onFocus={() => setFocus(true)}
        onBlur={(event) => {
          setFocus(false)
          onBlur && onBlur(event)
        }}
      />
    </div>
  ) : (
    <div className="relative">
      <DatePicker
        id={id}
        name={name}
        locale={locale}
        required={required}
        disabled={disabled}
        dateFormat={dateFormat}
        selected={currentDate}
        minDate={new Date()}
        placeholderText={placeholder}
        onChange={(date) => handleDateChange(date as Date)}
        onBlur={onBlur}
        className={classNames(
          'flex h-[4.8rem] w-full appearance-none rounded-none border bg-transparent px-spacing-xs font-roboto text-regular focus:ring-2',
          'text-text-secondary-light outline-none placeholder:text-text-secondary-light dark:text-text-secondary-dark dark:placeholder:text-text-secondary-dark',
          {
            'border-border-primary-light focus:ring-text-overline-light/20':
              !error,
            'border-error focus:ring-error/50': error
          }
        )}
      />
      <SVGIcon
        symbol="calendar-icon"
        className="pointer-events-none absolute right-[1.7rem] top-1/2 h-[1.6rem] w-[1.6rem] -translate-y-1/2 fill-text-secondary-light p-[.1rem] dark:fill-text-secondary-dark"
      />
    </div>
  )
}

const SelectControl = ({
  id,
  name,
  value,
  placeholder,
  options = [],
  required,
  disabled,
  error,
  onChange,
  onBlur
}: SelectControlProps) => {
  const [focus, setFocus] = useState(false)

  return (
    <div className="relative">
      <button
        tabIndex={-1}
        className={classNames(
          'pointer-events-none flex h-[4.8rem] w-full items-center justify-between border bg-transparent px-spacing-xs font-roboto text-regular outline-none focus:ring-2',
          'text-text-secondary-light dark:text-text-secondary-dark',
          {
            'ring-2 ring-text-overline-light/20': focus && !error,
            'border-error ring-2 ring-error/50': focus && error
          },
          {
            'border-border-primary-light focus:ring-text-overline-light/20':
              !error,
            'border-error focus:ring-error/50': error
          }
        )}
      >
        <Typography variant="regular" className="!m-0">
          {value || placeholder}
        </Typography>
        <SVGIcon
          symbol="chevron-icon"
          className="h-[1.6rem] w-[1.6rem] fill-text-secondary-light px-[.2rem] py-[.45rem] dark:fill-text-secondary-dark"
        />
      </button>
      <select
        id={id}
        name={name}
        value={value}
        required={required}
        disabled={disabled}
        className="absolute left-0 top-0 flex h-full w-full px-spacing-xs opacity-0"
        onFocus={() => setFocus(true)}
        onChange={onChange}
        onBlur={(event) => {
          setFocus(false)
          onBlur && onBlur(event)
        }}
      >
        <option value="-1" disabled>
          {placeholder}
        </option>
        {options?.map((option) => (
          <option key={`${name}-${option}`} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )
}

const FormControl = ({
  id,
  type,
  label,
  error,
  checked,
  placeholder,
  className,
  ...props
}: FormControlProps) => {
  const Component = (type === 'textarea' ? 'textarea' : 'input') as ElementType

  return (
    <div
      className={classNames(className, {
        hidden: type === 'hidden'
      })}
    >
      {type === 'checkbox' ? (
        <label
          htmlFor={id}
          className="relative flex items-start space-x-spacing-sm"
        >
          <input
            id={id}
            type={type}
            checked={checked}
            className="h-spacing-xs w-spacing-xs opacity-0 [&:checked+div]:after:bg-surface-primary-invert-dark dark:[&:checked+div]:after:bg-surface-primary-invert-light"
            {...props}
          />
          <div
            className={classNames(
              'absolute -top-[.3rem] left-0 !m-0 h-spacing-xs w-spacing-xs shrink-0 cursor-pointer rounded-full border border-text-overline-light',
              'after:absolute after:left-1/2 after:top-1/2 after:h-spacing-xxs after:w-spacing-xxs after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full',
              {
                'border-error': error
              }
            )}
          />
          <Typography
            variant="small"
            className={classNames(
              'text-text-secondary-light dark:text-text-secondary-dark',
              '[&>#required-data]:mt-spacing-xxs [&>#required-data]:inline-block',
              {
                '!text-error': error
              }
            )}
          >
            {label}
          </Typography>
        </label>
      ) : type === 'date' ? (
        <DateControl
          id={id}
          placeholder={placeholder}
          error={error}
          {...props}
        />
      ) : type === 'select' ? (
        <SelectControl
          id={id}
          placeholder={placeholder}
          error={error}
          {...props}
        />
      ) : (
        <Component
          id={id}
          type={type}
          placeholder={placeholder}
          className={classNames(
            'flex h-[4.8rem] w-full appearance-none rounded-none border bg-transparent px-spacing-xs font-roboto text-regular focus:ring-2',
            'text-text-secondary-light outline-none placeholder:text-text-secondary-light dark:text-text-secondary-dark dark:placeholder:text-text-secondary-dark',
            { 'min-h-spacing-2xl py-[1.2rem]': type === 'textarea' },
            {
              'border-border-primary-light focus:ring-text-overline-light/20':
                !error,
              'border-error focus:ring-error/50': error
            }
          )}
          {...props}
        />
      )}
    </div>
  )
}

export default FormControl
