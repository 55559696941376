import classNames from 'classnames'

import SVGIcon from '@/components/base/SVGIcon'

type SwiperNavigationProps = Partial<{
  className: string
  onPrevClick: () => void
  onNextClick: () => void
}>

const SwiperNavigation = ({
  className,
  onPrevClick,
  onNextClick
}: SwiperNavigationProps) => {
  return (
    <div className={classNames('relative', className)}>
      <div className="absolute bottom-0 left-0 flex w-full space-x-[.1rem]">
        <button
          onClick={onPrevClick}
          className="flex h-[6.4rem] w-[6.4rem] items-center justify-center bg-background-primary-invert-light dark:bg-background-primary-invert-dark"
          aria-label="Prev slide"
        >
          <SVGIcon
            symbol="prev-arrow-icon"
            className="h-[1.2rem] w-[.8rem] fill-surface-primary-invert-light dark:fill-surface-primary-invert-dark"
          />
        </button>
        <button
          onClick={onNextClick}
          className="flex h-[6.4rem] w-[6.4rem] items-center justify-center bg-background-primary-invert-light dark:bg-background-primary-invert-dark"
          aria-label="Next slide"
        >
          <SVGIcon
            symbol="next-arrow-icon"
            className="h-[1.2rem] w-[.8rem] fill-surface-primary-invert-light dark:fill-surface-primary-invert-dark"
          />
        </button>
      </div>
    </div>
  )
}

export default SwiperNavigation
