import ImageGallery from '@/components/sections/ImageGallery'

import type { SectionTheme, WPImage } from '@/types'

type SingleImageProps = SectionTheme & {
  image: Partial<WPImage>
}

const SingleImage = ({ image, ...props }: SingleImageProps) => {
  return <ImageGallery items={[{ image }]} {...props} />
}

export default SingleImage
