import { useRef } from 'react'
import classNames from 'classnames'
import { Swiper as SwiperImpl } from 'swiper'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import ImageParallax from '@/components/base/ImageParallax'
import LazyImage from '@/components/base/LazyImage'
import Section from '@/components/base/Section'
import SwiperNavigation from '@/components/base/SwiperNavigation'
import TextBlock, { TextBlockContentProps } from '@/components/base/TextBlock'

import 'swiper/css'

import 'swiper/css/pagination'

import type { SectionTheme, WPImage } from '@/types'

type ImageGalleryProps = SectionTheme & {
  items?: Partial<{
    image: Partial<WPImage>
    heading: TextBlockContentProps['heading']
    paragraphRegular: TextBlockContentProps['paragraphRegular']
  }>[]
}

const ImageGallery = ({ theme, items = [] }: ImageGalleryProps) => {
  const swiper = useRef<SwiperImpl>(null!)

  return (
    <Section theme={theme}>
      <Container>
        <Grid>
          <div className="col-span-2 md:col-span-6 lg:col-span-12">
            <Swiper
              modules={[Pagination]}
              className={classNames(
                'select-none [--swiper-pagination-bottom:0] [--swiper-pagination-bullet-inactive-color:#1C1917] [--swiper-pagination-bullet-inactive-opacity:1] [--swiper-pagination-color:#1C1917] dark:[--swiper-pagination-bullet-inactive-color:white] dark:[--swiper-pagination-color:white]',
                {
                  '!pb-spacing-md': items.length > 1
                }
              )}
              spaceBetween={0}
              slidesPerView={1}
              slidesPerGroup={1}
              pagination={{
                type: 'bullets',
                enabled: true,
                dynamicBullets: true
              }}
              onBeforeInit={(instance) => {
                swiper.current = instance
              }}
              breakpoints={{
                768: {
                  pagination: { enabled: false }
                }
              }}
            >
              {items.map(({ image, heading, paragraphRegular }, index) => (
                <SwiperSlide key={`slide-${index}`} className="dark relative">
                  <div
                    className={classNames(
                      'aspect-h-1 aspect-w-1 lg:aspect-h-9 lg:aspect-w-16',
                      {
                        'after:absolute after:left-0 after:top-0 after:h-full after:w-full after:transform-gpu after:bg-black-light/40':
                          heading
                      }
                    )}
                  >
                    <ImageParallax fill>
                      {(ref) => (
                        <LazyImage
                          ref={ref}
                          src={image?.sizes?.large.url || image?.url || ''}
                          alt={image?.alt || ''}
                          fill
                          className="object-cover object-center"
                        />
                      )}
                    </ImageParallax>
                  </div>
                  <div className="absolute left-0 top-0 grid h-full w-full transform-gpu grid-cols-2 gap-x-gutter md:grid-cols-6 lg:grid-cols-12">
                    <TextBlock
                      density="medium"
                      heading={heading}
                      titleVariant="h4"
                      paragraphRegular={paragraphRegular}
                      rootClassName="relative col-span-2 md:col-span-4 md:col-start-2 lg:col-start-2 lg:col-span-6 px-[2rem] mt-auto md:px-0 mb-spacing-lg md:mb-spacing-2xl"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {items.length > 1 ? (
            <SwiperNavigation
              className="z-10 col-span-2 hidden md:col-span-4 md:col-start-2 md:block lg:col-span-10 lg:col-start-2"
              onPrevClick={() => swiper.current.slidePrev()}
              onNextClick={() => swiper.current.slideNext()}
            />
          ) : null}
        </Grid>
      </Container>
    </Section>
  )
}

export default ImageGallery
