import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

const Portal = ({ children }: PropsWithChildren) => {
  const ref = useRef<Element>(null!)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.body
    setMounted(true)
  }, [])

  return mounted ? createPortal(children, ref.current) : null
}

export default Portal
